@import 'fonts';
@import 'reset';
@import 'custom-mat-theme';
@import 'buttons';
@import 'mini-layouts';
@import 'icon-font';
@import 'progress-bar';
@import 'palette';

lib-rating .rating {
  margin-top: 1px !important;
}

h1 {
  font-size: 22px;
}
.underline {
  text-decoration: underline;
}
.dark-backdrop {
  background-color: #ffffff;
}
.full-width {
  width: 100%;
  padding: 0 6.42%;
  padding-top: 20px;
  padding-bottom: 20px;
}
.reset-display {
  display: initial !important;
}
.tiny-logo {
  width: 28px;
}

.tiny {
  font-size: 12px;
  font-weight: 300;
}
.tiny-title {
  margin-bottom: 4px;
  display: block;
}
.big {
  font-size: 21px;
  color: $dark-gray;
  font-weight: 500;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
.title-opportunities {
  margin-bottom: 10px;
}

.link-active {
  color: $blue;
  font-weight: 500;
}
.full-width {
  overflow-y: scroll;
}
.icon-button,
.action-icon {
  cursor: pointer;
}
.blue {
  color: $blue-light;
}
.bold {
  font-weight: 500;
}

.no-items-found {
  color: $dark-gray;
  font-size: 20px;
  text-align: center;
  margin: 60px 0;
}

.tooltip-container {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
}

.tooltip-container-not-dots {
  position: relative;
  display: inline-block;
}

.tooltip-container-not-dots .tooltiptext {
  visibility: hidden;
  word-wrap: break-word;
  width: 120px;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  background-color: #313131;
  font-size: 10px;

  /* Position the tooltip */
  position: absolute;
  z-index: 2;
}

.tooltip-container-not-dots .tooltiptext-left {
  visibility: hidden;
  width: 130px;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  background-color: #313131;
  font-size: 10px;
  word-wrap: break-word;
  white-space: pre;
  /* Position the tooltip */
  position: absolute;
  z-index: 2;
}

.tooltip-container-not-dots .tooltiptext-right2 {
  visibility: hidden;
  width: 130px;
  color: #fff;
  text-align: center !important;
  border-radius: 6px;
  padding: 5px;
  background-color: #313131;
  font-size: 10px;
  word-wrap: break-word;
  /* Position the tooltip */
  position: absolute;
  margin-left: 10px;
  z-index: 2;
}

.tooltip-container .tooltiptext {
  visibility: hidden;
  width: 120px;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  background-color: #313131;
  font-size: 10px;
  word-wrap: break-word;

  /* Position the tooltip */
  position: absolute;
  z-index: 2;
}

.tooltip-container .tooltiptext-right {
  visibility: hidden;
  width: 120px;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  background-color: #313131;
  font-size: 10px;
  word-wrap: break-word;

  /* Position the tooltip */
  position: absolute;
  z-index: 2;
  top: 100%;
  left: 50%;
  margin-left: -120px;
}

.tooltip-container-not-dots .tooltiptext-right {
  visibility: hidden;
  width: 120px;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  background-color: #313131;
  font-size: 10px;
  word-wrap: break-word;

  /* Position the tooltip */
  position: absolute;
  z-index: 100;
  top: 100%;
  left: 50%;
  margin-left: -120px;
}

.tooltip-container-not-dots .tooltiptext-bottom {
  visibility: hidden;
  width: 120px;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  background-color: #313131;
  font-size: 10px;
  /* Position the tooltip */
  position: absolute;
  word-wrap: break-word;
  z-index: 100;
  top: 100%;
  left: 50%;
  margin-left: -60px;
}

.tooltip-container:hover .tooltiptext,
.tooltiptext-right {
  visibility: visible;
}

.tooltip-container-not-dots:hover .tooltiptext-bottom {
  visibility: visible;
}

.tooltip-container-not-dots:hover .tooltiptext,
.tooltiptext-right {
  visibility: visible;
}

.tooltip-container:hover .tooltiptext-right {
  visibility: visible;
}

.tooltip-container-not-dots:hover .tooltiptext-right {
  visibility: visible;
}
.tooltip-container-not-dots:focus .tooltiptext-right {
  visibility: visible;
}

.tooltip-container-not-dots:focus .tooltiptext-bottom {
  visibility: visible;
}

.tooltip-container-not-dots:hover .tooltiptext-bottom {
  visibility: visible;
}

.tooltip-container-not-dots:hover .tooltiptext-left {
  visibility: visible;
}
.tooltip-container-not-dots:focus .tooltiptext-left {
  visibility: visible;
}

.tooltip-container-not-dots:hover .tooltiptext-right2 {
  visibility: visible;
}
.tooltip-container-not-dots:focus .tooltiptext-right2 {
  visibility: visible;
}

.mat-slide-toggle-bar {
  background-color: rgb(184 185 185 / 54%) !important;
}
.mat-slide-toggle .mat-slide-toggle-thumb {
  background-color: #0068dc !important;
}
.mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
  background-color: #0068dc !important;
}

.upload-icon {
  content: url(../assets/icons/upload_icon.svg);
  width: 21px;
  height: 21px;
}

.post-add {
  content: url(../assets/icons/post_add.svg);
  width: 21px;
  height: 21px;
  filter: invert(81%) sepia(0%) saturate(0%) hue-rotate(191deg) brightness(87%)
    contrast(87%);
}

.loader-icon {
  content: url(../assets/icons/loading-spinner.svg);
  width: 18px;
  height: 18px;
  filter: invert(34%) sepia(90%) saturate(6351%) hue-rotate(202deg)
    brightness(97%) contrast(101%);
}

.info-icon {
  content: url(../assets/icons/info-24px.svg);
  width: 18px;
  height: 18px;
}

.more-dots {
  content: url(../assets/icons/more_dots.svg);
  width: 18px;
  height: 18px;
}

.download-off {
  content: url(../assets/icons/download-off.svg);
  width: 21px;
  height: 21px;
}
.hyperlink {
  cursor: pointer;
  color: $blue;
  font-weight: 500;
}

fs-ui-button {
  display: block;
  width: 100%;
  margin-bottom: 20px;

  @media #{$desktop-bp} {
    width: 300px;
  }
}

.actionButtons fs-ui-button:first-child {
  margin-right: 10px;
}
.button-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.button-direction-column {
  flex-direction: column;
}

.eval-red {
  color: $red;
  font-weight: 400;
}

.eval-green {
  color: $green;
  font-weight: 400;
}

.arrow-upward {
  content: url(../assets/icons/arrow_upward_black_24dp.svg);
  width: 18px;
  height: 18px;
  filter: invert(47%) sepia(8%) saturate(533%) hue-rotate(179deg)
    brightness(90%) contrast(83%);
}

.arrow-downward {
  content: url(../assets/icons/arrow_downward_black_24dp.svg);
  width: 18px;
  height: 18px;
  filter: invert(47%) sepia(8%) saturate(533%) hue-rotate(179deg)
    brightness(90%) contrast(83%);
}

.expand-less {
  content: url(../assets/icons/expand_less.svg);
  width: 18px;
  height: 18px;
  filter: invert(47%) sepia(8%) saturate(533%) hue-rotate(179deg)
    brightness(90%) contrast(83%);
}

.expand-more {
  content: url(../assets/icons/expand_more.svg);
  width: 18px;
  height: 18px;
  filter: invert(47%) sepia(8%) saturate(533%) hue-rotate(179deg)
    brightness(90%) contrast(83%);
}

.arrow-forward {
  content: url(../assets/icons/arrow_forward-24px.svg);
  width: 18px;
  height: 18px;
  filter: invert(47%) sepia(8%) saturate(533%) hue-rotate(179deg)
    brightness(90%) contrast(83%);
}

lib-invoice-card {
  display: block;
  margin-bottom: 35px;
  @media #{$desktop-bp} {
    margin-bottom: 50px;
  }
}

lib-text-icon .dotted {
  max-width: calc(100vw - 860px) !important;
}

:host ::ng-deep
  mat-dialog-container
  lib-finsmart-banks-layout
  .actions-buttons-container
  fs-ui-button {
  width: 100% !important;
}

// .tf-v1-sidetab-button{
//   background-color: red !important;
// }

// .tf-v1-popover-button{
//   background-color: red !important;

html.cdk-global-scrollblock {
  position: initial !important;
}

// html.cdk-global-scrollblock body {
//   position: fixed;
// }