@import "palette";
@import "responsive";

.button {
    display: block;
    font-size: 16px;
    border-radius: 4px;
    border: none;
    text-align: center;
    line-height: 20px;
    width: 100%;
    padding: 16px 0;
    cursor: pointer;
    background-color: $white;
    font-weight: 300;
    font-family: 'Chivo', sans-serif;

    @media #{$desktop-bp} {
        width: 300px;
        display: block;
        margin-left: auto;
        margin-right: auto;
    }
}
.button-blue {
    background-color: $blue-light;
    color: $white;
}
.button-black {
    background-color: #2d2d2d;;
    color: $white;
}
.button-red {
    background-color: red;
    color: $white;
}
.button-dark-blue {
    background-color: $dark-blue;
    color: $white;
}
.button-gray {
    background-color: $light-gray;
    color: $dark-gray;
}
.button-dark-gray {
    background-color: $dark-gray;
    color: #ffffff;
}

.button-gray-outline {
    border: 2px solid $light-gray;
    color: $dark-gray;
}