@charset "UTF-8";

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-add-file:before {
  content: '\e95b';
}
.icon-edit-border .path1:before {
  content: '\e95c';
  color: rgb(2, 86, 255);
}
.icon-bank-accounts:before {
  content: '\e900';
}
.icon-financial-transactions:before {
  content: '\e901';
}
.icon-invoices:before {
  content: '\e902';
}
.icon-logout:before {
  content: '\e903';
}
.icon-my-investments:before {
  content: '\e904';
}
.icon-opportunities:before {
  content: '\e905';
}
.icon-profile:before {
  content: '\e906';
}
.icon-system-settings:before {
  content: '\e907';
}
.icon-user-accounts:before {
  content: '\e908';
}

.icon-retention:before {
  content: '\e915';
  color: #6c727c;
}
.icon-excel:before {
  content: '\e911';
  color: #6c727c;
}
.icon-price_change:before {
  content: '\e90e';
  color: #6c727c;
}
.icon-history:before {
  content: '\e906';
  color: #6c727c;
}
.icon-settings:before {
  content: '\e907';
  color: #6c727c;
}
.icon-stacked-bar:before {
  content: '\e908';
  color: #6c727c;
}
.icon-receipt:before {
  content: '\e909';
  color: #0256ff;
}
.icon-publish:before {
  content: '\e90a';
  color: #0256ff;
}
.icon-not_published:before {
  content: '\e90b';
  color: #6c727c;
}
.icon-funnel:before {
  content: '\e902';
}
.icon-company:before {
  content: '\e955';
}
.icon-pending_action:before {
  content: '\e917';
}
.icon-event:before {
  content: '\e918';
}
.icon-donut:before {
  content: '\e919';
}
.icon-receipt_long:before {
  content: '\e91a';
}
.icon-assignment:before {
  content: '\e91b';
}
.icon-leaderboard:before {
  content: '\e916';
}
.icon-pending:before {
  content: '\e914';
}
.icon-arrow_forward:before {
  content: '\e913';
}
.icon-download:before {
  content: '\e912';
}
.icon-timeline:before {
  content: '\e910';
}
.icon-savings:before {
  content: '\e90f';
}
.icon-arrow_left:before {
  content: '\e90c';
}
.icon-arrow_right:before {
  content: '\e90d';
}
.icon-annoucement:before {
  content: '\e905';
}
.icon-mail:before {
  content: '\e904';
}
.icon-funnel_filled:before {
  content: '\e900';
}
.icon-company_filled:before {
  content: '\e901';
}
.icon-schedule:before {
  content: '\e91c';
}
.icon-for_sale:before {
  content: '\e91d';
}
.icon-info:before {
  content: '\e91e';
}
.icon-today_calendar:before {
  content: '\e91f';
  color: #6c727c;
}
.icon-waving_hand:before {
  content: '\e920';
  color: #6c727c;
}
.icon-attach_file:before {
  content: '\e921';
  color: #6c727c;
}
.icon-content_paste:before {
  content: '\e922';
  color: #fff;
}
.icon-point_sale:before {
  content: '\e923';
  color: #6c727c;
}
.icon-settings_normal:before {
  content: '\e924';
}
.icon-description:before {
  content: '\e925';
}
.icon-help_info:before {
  content: '\e928';
}
.icon-double_arrow_down:before {
  content: '\e926';
  color: #fff;
}
.icon-fx_formula:before {
  content: '\e927';
}
.icon-article:before {
  content: '\e929';
}

.icon-save:before {
  content: '\e92c';
}

.icon-content_copy:before {
  content: '\e92d';
}

.icon-cartera:before {
  content: '\e92e';
}
.icon-more_horiz:before {
  content: '\e92f';
}
.icon-open_new:before {
  content: '\e930';
}

.icon-filter_list:before {
  content: '\e931';
}
.icon-check-box-empty:before {
  content: '\e932';
  color: #6c727c;
}
.icon-check-box-approved:before {
  content: '\e933';
  color: #12ab64;
}
.icon-check-box-denied:before {
  content: '\e934';
  color: #ef4444;
}
.icon-check-box-approve-filled:before {
  content: '\e935';
  color: #6c727c;
}
.icon-date-picker:before {
  content: '\e936';
  color: #6c727c;
}
.icon-type-number:before {
  content: '\e937';
  color: #6c727c;
}
.icon-type-operation:before {
  content: '\e938';
  color: #6c727c;
}
.icon-type-text:before {
  content: '\e939';
  color: #6c727c;
}
.icon-check-box-pending:before {
  content: '\e93a';
  color: #ffc400;
}
.icon-delete:before {
  content: '\e93b';
}
.icon-add:before {
  content: '\e93c';
}
.icon-observations:before {
  content: '\e941';
}
.icon-users:before {
  content: '\e93d';
}

.icon-business:before {
  content: '\e92b';
}

.icon-telegram:before {
  content: '\e93e';
}
.icon-visibility_black:before {
  content: '\e93f';
}
.icon-note_add_black:before {
  content: '\e940';
}
.icon-comments:before {
  content: '\e942';
  color: #fff;
}
.icon-edit:before {
  content: '\e943';
}
.icon-cancel:before {
  content: '\e944';
}
.icon-visibility_off_black:before {
  content: '\e945';
}
.icon-person:before {
  content: '\e946';
  color: #fff;
}

.icon-contacts:before {
  content: '\e947';
}
.icon-autorenew_black:before {
  content: '\e948';
}

.icon-add-user:before {
  content: '\e949';
  color: #fff;
}

.icon-check_circle:before {
  content: '\e92a';
}

.icon-fullscreen_black:before {
  content: '\e94a';
}
.icon-download_off:before {
  content: '\e94b';
}
.icon-grouping:before {
  content: '\e94c';
}
.icon-upload_icon:before {
  content: '\e94d';
}
.icon-post_add:before {
  content: '\e94e';
}
.icon-delete_icon_filled:before {
  content: '\e94f';
}

.icon-circle-yellow:before {
  content: '\e950';
  color: #ffc400;
}
.icon-circle-gray:before {
  content: '\e951';
}
.icon-circle-red:before {
  content: '\e952';
  color: #ef4444;
}
.icon-circle-green:before {
  content: '\e953';
  color: #2eb67d;
}
.icon-arrow_down:before {
  content: '\e954';
}

.icon-warning:before {
  content: '\e903';
  color: #ffc400;
}

.icon-done_black:before {
  content: '\e957';
}

.icon-warning-rounded:before {
  content: '\e956';
  color: #f00;
}

.icon-arrow-right:before {
  content: '\e958';
}

.icon-close:before {
  content: '\e95e';
}
.icon-approved:before {
  content: '\e95f';
  color: #12ab64;
}

.icon-ws-outline:before {
  content: '\e960';
}

.icon-ws-filled:before {
  content: '\e961';
}

// .icon-denied:before {
//   content: "\e962";
//   color: #f00;
// }
// .icon-pending-check:before {
//   content: "\e963";
//   color: #ffc400;
// }
// .icon-accepted:before {
//   content: "\e964";
//   color: #12ab64;
// }
// .icon-todo-check:before {
//   content: "\e965";
// }
// .icon-money:before {
//   content: "\e966";
// }
// .icon-Undo:before {
//   content: "\e967";
// }
// .icon-investment:before {
//   content: "\e968";
// }
.icon-denied:before {
  content: '\e969';
  color: #f00;
}
.icon-todo-check:before {
  content: '\e964';
  color: #6c727c;
}
.icon-money:before {
  content: '\e965';
  color: #0256ff;
}
.icon-accepted:before {
  content: '\e966';
  color: #12ab64;
}
.icon-Undo:before {
  content: '\e967';
  color: #0256ff;
}
.icon-pending-check:before {
  content: '\e968';
  color: #ffc400;
}

.icon-investment:before {
  content: '\e96a';
  color: #0256ff;
}

.icon-thumb-down:before {
  content: '\e962';
}

.icon-thumb-up:before {
  content: '\e963';
}

.icon-Star-border:before {
  content: '\e96b';
}

.icon-Star:before {
  content: '\e96c';
}

.icon-store:before {
  content: "\e96d";
  color: #0256ff;
  font-size: 24px !important;
}