@import url(https://fonts.googleapis.com/css2?family=Chivo:wght@300;400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Work+Sans:wght@300;400;500;700&display=swap);

@charset "UTF-8";
@font-face {
  font-family: "icomoon";
  src: url('icomoon.43730f25bcee6f7195b0.eot');
  src: url('icomoon.43730f25bcee6f7195b0.eot') format("embedded-opentype"), url('icomoon.d6a0d2f9460bdb4c0fbc.ttf') format("truetype"), url('icomoon.79908864cda4000fd2df.woff') format("woff"), url('icomoon.b19e724945003be5a612.svg') format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
html {
  box-sizing: border-box;
}
/* THIS CAUSES INPUT TO NOT WORK
ALSO ACCORDRING TO THIS, IT'S NOT GOOD FROM A UX STANDING POINT
https://ux.stackexchange.com/a/83228
*,
*:before,
*:after {
  box-sizing: inherit;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
   -khtml-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
*/
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font-weight: 300;
  font-family: "Chivo", sans-serif;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
  margin: 20px 16px !important;
  margin-top: 20px !important;
}
@media screen and (min-width: 321px) {
  body {
    margin-left: 24px !important;
    margin-right: 24px !important;
  }
}
a {
  text-decoration: none;
  color: #000000;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before, blockquote:after {
  content: "";
  content: none;
}
q:before, q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: none;
  -webkit-text-fill-color: none;
  box-shadow: 0 0 0px 1000px #ffffff inset;
  -webkit-transition: background-color 5000s ease-in-out 0s;
  transition: background-color 5000s ease-in-out 0s;
}
button {
  border: none;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 500 !important;
}
::ng-deep .toast-card.success {
  background-color: #12AB64 !important;
}
::ng-deep .toast-card.danger {
  background-color: #FF0000 !important;
}
/* Colors for the ripple elements.*/
/* stylelint-disable-next-line material/theme-mixin-api */
/* stylelint-disable-next-line material/theme-mixin-api */
/* stylelint-disable material/no-prefixes */
/* stylelint-enable */
.mat-badge-content {
  font-weight: 600;
  font-size: 12px;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.mat-badge-small .mat-badge-content {
  font-size: 9px;
}
.mat-badge-large .mat-badge-content {
  font-size: 24px;
}
.mat-h1, .mat-headline, .mat-typography h1 {
  font: 400 24px/32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}
.mat-h2, .mat-title, .mat-typography h2 {
  font: 500 20px/32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}
.mat-h3, .mat-subheading-2, .mat-typography h3 {
  font: 400 16px/28px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}
.mat-h4, .mat-subheading-1, .mat-typography h4 {
  font: 400 15px/24px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}
.mat-h5, .mat-typography h5 {
  font: 400 calc(14px * 0.83)/20px Roboto, "Helvetica Neue", sans-serif;
  margin: 0 0 12px;
}
.mat-h6, .mat-typography h6 {
  font: 400 calc(14px * 0.67)/20px Roboto, "Helvetica Neue", sans-serif;
  margin: 0 0 12px;
}
.mat-body-strong, .mat-body-2 {
  font: 500 14px/24px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}
.mat-body, .mat-body-1, .mat-typography {
  font: 400 14px/20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}
.mat-body p, .mat-body-1 p, .mat-typography p {
  margin: 0 0 12px;
}
.mat-small, .mat-caption {
  font: 400 12px/20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}
.mat-display-4, .mat-typography .mat-display-4 {
  font: 300 112px/112px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: -0.05em;
  margin: 0 0 56px;
}
.mat-display-3, .mat-typography .mat-display-3 {
  font: 400 56px/56px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: -0.02em;
  margin: 0 0 64px;
}
.mat-display-2, .mat-typography .mat-display-2 {
  font: 400 45px/48px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: -0.005em;
  margin: 0 0 64px;
}
.mat-display-1, .mat-typography .mat-display-1 {
  font: 400 34px/40px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 64px;
}
.mat-bottom-sheet-container {
  font: 400 14px/20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}
.mat-button, .mat-raised-button, .mat-icon-button, .mat-stroked-button,
.mat-flat-button, .mat-fab, .mat-mini-fab {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500;
}
.mat-button-toggle {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.mat-card {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.mat-card-title {
  font-size: 24px;
  font-weight: 500;
}
.mat-card-header .mat-card-title {
  font-size: 20px;
}
.mat-card-subtitle,
.mat-card-content {
  font-size: 14px;
}
.mat-checkbox {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.mat-checkbox-layout .mat-checkbox-label {
  line-height: 24px;
}
.mat-chip {
  font-size: 14px;
  font-weight: 500;
}
.mat-chip .mat-chip-trailing-icon.mat-icon,
.mat-chip .mat-chip-remove.mat-icon {
  font-size: 18px;
}
.mat-table {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.mat-header-cell {
  font-size: 12px;
  font-weight: 500;
}
.mat-cell, .mat-footer-cell {
  font-size: 14px;
}
.mat-calendar {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.mat-calendar-body {
  font-size: 13px;
}
.mat-calendar-body-label,
.mat-calendar-period-button {
  font-size: 14px;
  font-weight: 500;
}
.mat-calendar-table-header th {
  font-size: 11px;
  font-weight: 400;
}
.mat-dialog-title {
  font: 500 20px/32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}
.mat-expansion-panel-header {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 15px;
  font-weight: 400;
}
.mat-expansion-panel-content {
  font: 400 14px/20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}
.mat-form-field {
  font-size: inherit;
  font-weight: 400;
  line-height: 1.125;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}
.mat-form-field-wrapper {
  padding-bottom: 1.34375em;
}
.mat-form-field-prefix .mat-icon,
.mat-form-field-suffix .mat-icon {
  font-size: 150%;
  line-height: 1.125;
}
.mat-form-field-prefix .mat-icon-button,
.mat-form-field-suffix .mat-icon-button {
  height: 1.5em;
  width: 1.5em;
}
.mat-form-field-prefix .mat-icon-button .mat-icon,
.mat-form-field-suffix .mat-icon-button .mat-icon {
  height: 1.125em;
  line-height: 1.125;
}
.mat-form-field-infix {
  padding: 0.5em 0;
  border-top: 0.84375em solid transparent;
}
.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.34375em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.34374em) scale(0.75);
  width: 133.3333433333%;
}
.mat-form-field-label-wrapper {
  top: -0.84375em;
  padding-top: 0.84375em;
}
.mat-form-field-label {
  top: 1.34375em;
}
.mat-form-field-underline {
  bottom: 1.34375em;
}
.mat-form-field-subscript-wrapper {
  font-size: 75%;
  margin-top: 0.6666666667em;
  top: calc(100% - 1.7916666667em);
}
.mat-form-field-appearance-legacy .mat-form-field-wrapper {
  padding-bottom: 1.25em;
}
.mat-form-field-appearance-legacy .mat-form-field-infix {
  padding: 0.4375em 0;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.001px);
  -ms-transform: translateY(-1.28125em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00101px);
  -ms-transform: translateY(-1.28124em) scale(0.75);
  width: 133.3333433333%;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00102px);
  -ms-transform: translateY(-1.28123em) scale(0.75);
  width: 133.3333533333%;
}
.mat-form-field-appearance-legacy .mat-form-field-label {
  top: 1.28125em;
}
.mat-form-field-appearance-legacy .mat-form-field-underline {
  bottom: 1.25em;
}
.mat-form-field-appearance-legacy .mat-form-field-subscript-wrapper {
  margin-top: 0.5416666667em;
  top: calc(100% - 1.6666666667em);
}
@media print {
  .mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28122em) scale(0.75);
  }
  .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28121em) scale(0.75);
  }
  .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.2812em) scale(0.75);
  }
}
.mat-form-field-appearance-fill .mat-form-field-infix {
  padding: 0.25em 0 0.75em 0;
}
.mat-form-field-appearance-fill .mat-form-field-label {
  top: 1.09375em;
  margin-top: -0.5em;
}
.mat-form-field-appearance-fill.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.59375em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.59374em) scale(0.75);
  width: 133.3333433333%;
}
.mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 1em 0 1em 0;
}
.mat-form-field-appearance-outline .mat-form-field-label {
  top: 1.84375em;
  margin-top: -0.25em;
}
.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.59375em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.59374em) scale(0.75);
  width: 133.3333433333%;
}
.mat-grid-tile-header,
.mat-grid-tile-footer {
  font-size: 14px;
}
.mat-grid-tile-header .mat-line,
.mat-grid-tile-footer .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-grid-tile-header .mat-line:nth-child(n+2),
.mat-grid-tile-footer .mat-line:nth-child(n+2) {
  font-size: 12px;
}
input.mat-input-element {
  margin-top: -0.0625em;
}
.mat-menu-item {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 400;
}
.mat-paginator,
.mat-paginator-page-size .mat-select-trigger {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
}
.mat-radio-button {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.mat-select {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.mat-select-trigger {
  height: 1.125em;
}
.mat-slide-toggle-content {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.mat-slider-thumb-label-text {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
  font-weight: 500;
}
.mat-stepper-vertical, .mat-stepper-horizontal {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.mat-step-label {
  font-size: 14px;
  font-weight: 400;
}
.mat-step-sub-label-error {
  font-weight: normal;
}
.mat-step-label-error {
  font-size: 14px;
}
.mat-step-label-selected {
  font-size: 14px;
  font-weight: 500;
}
.mat-tab-group {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.mat-tab-label, .mat-tab-link {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500;
}
.mat-toolbar,
.mat-toolbar h1,
.mat-toolbar h2,
.mat-toolbar h3,
.mat-toolbar h4,
.mat-toolbar h5,
.mat-toolbar h6 {
  font: 500 20px/32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0;
}
.mat-tooltip {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 10px;
  padding-top: 6px;
  padding-bottom: 6px;
}
.mat-tooltip-handset {
  font-size: 14px;
  padding-top: 8px;
  padding-bottom: 8px;
}
.mat-list-item {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.mat-list-option {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.mat-list-base .mat-list-item {
  font-size: 16px;
}
.mat-list-base .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base .mat-list-item .mat-line:nth-child(n+2) {
  font-size: 14px;
}
.mat-list-base .mat-list-option {
  font-size: 16px;
}
.mat-list-base .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base .mat-list-option .mat-line:nth-child(n+2) {
  font-size: 14px;
}
.mat-list-base .mat-subheader {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500;
}
.mat-list-base[dense] .mat-list-item {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base[dense] .mat-list-item .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-option {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base[dense] .mat-list-option .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.mat-list-base[dense] .mat-subheader {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
  font-weight: 500;
}
.mat-option {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 16px;
}
.mat-optgroup-label {
  font: 500 14px/24px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}
.mat-simple-snackbar {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
}
.mat-simple-snackbar-action {
  line-height: 1;
  font-family: inherit;
  font-size: inherit;
  font-weight: 500;
}
.mat-tree {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.mat-tree-node,
.mat-nested-tree-node {
  font-weight: 400;
  font-size: 14px;
}
.mat-ripple {
  overflow: hidden;
  position: relative;
}
.mat-ripple:not(:empty) {
  transform: translateZ(0);
}
.mat-ripple.mat-ripple-unbounded {
  overflow: visible;
}
.mat-ripple-element {
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
  transition: opacity, transform 0ms cubic-bezier(0, 0, 0.2, 1);
  transform: scale(0);
}
.cdk-high-contrast-active .mat-ripple-element {
  display: none;
}
.cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
}
.cdk-overlay-container, .cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
.cdk-overlay-container {
  position: fixed;
  z-index: 1000;
}
.cdk-overlay-container:empty {
  display: none;
}
.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
}
.cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%;
}
.cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
}
.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}
@media screen and (-ms-high-contrast: active) {
  .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
    opacity: 0.6;
  }
}
.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.32);
}
.cdk-overlay-transparent-backdrop, .cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0;
}
.cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px;
}
.cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}
@-webkit-keyframes cdk-text-field-autofill-start {
  /*!*/
}
@keyframes cdk-text-field-autofill-start {
  /*!*/
}
@-webkit-keyframes cdk-text-field-autofill-end {
  /*!*/
}
@keyframes cdk-text-field-autofill-end {
  /*!*/
}
.cdk-text-field-autofill-monitored:-webkit-autofill {
  -webkit-animation: cdk-text-field-autofill-start 0s 1ms;
          animation: cdk-text-field-autofill-start 0s 1ms;
}
.cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
  -webkit-animation: cdk-text-field-autofill-end 0s 1ms;
          animation: cdk-text-field-autofill-end 0s 1ms;
}
textarea.cdk-textarea-autosize {
  resize: none;
}
textarea.cdk-textarea-autosize-measuring {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: auto !important;
  overflow: hidden !important;
}
textarea.cdk-textarea-autosize-measuring-firefox {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: 0 !important;
}
.mat-focus-indicator {
  position: relative;
}
.mat-mdc-focus-indicator {
  position: relative;
}
.mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.mat-option {
  color: rgba(0, 0, 0, 0.87);
}
.mat-option:hover:not(.mat-option-disabled), .mat-option:focus:not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.mat-option.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.mat-option.mat-active {
  background: rgba(0, 0, 0, 0.04);
  color: rgba(0, 0, 0, 0.87);
}
.mat-option.mat-option-disabled {
  color: rgba(0, 0, 0, 0.38);
}
.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #3f51b5;
}
.mat-accent .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #ff4081;
}
.mat-warn .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #f44336;
}
.mat-optgroup-label {
  color: rgba(0, 0, 0, 0.54);
}
.mat-optgroup-disabled .mat-optgroup-label {
  color: rgba(0, 0, 0, 0.38);
}
.mat-pseudo-checkbox {
  color: rgba(0, 0, 0, 0.54);
}
.mat-pseudo-checkbox::after {
  color: #fafafa;
}
.mat-pseudo-checkbox-disabled {
  color: #b0b0b0;
}
.mat-primary .mat-pseudo-checkbox-checked,
.mat-primary .mat-pseudo-checkbox-indeterminate {
  background: #3f51b5;
}
.mat-pseudo-checkbox-checked,
.mat-pseudo-checkbox-indeterminate,
.mat-accent .mat-pseudo-checkbox-checked,
.mat-accent .mat-pseudo-checkbox-indeterminate {
  background: #ff4081;
}
.mat-warn .mat-pseudo-checkbox-checked,
.mat-warn .mat-pseudo-checkbox-indeterminate {
  background: #f44336;
}
.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,
.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled {
  background: #b0b0b0;
}
.mat-app-background {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}
.mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}
.mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}
.mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}
.mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}
.mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}
.mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}
.mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}
.mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}
.mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}
.mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}
.mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}
.mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}
.mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}
.mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}
.mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}
.mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.mat-theme-loaded-marker {
  display: none;
}
.mat-autocomplete-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-autocomplete-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover) {
  background: white;
}
.mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover):not(.mat-option-disabled) {
  color: rgba(0, 0, 0, 0.87);
}
.mat-badge-content {
  color: white;
  background: #3f51b5;
}
.cdk-high-contrast-active .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}
.mat-badge-accent .mat-badge-content {
  background: #ff4081;
  color: white;
}
.mat-badge-warn .mat-badge-content {
  color: white;
  background: #f44336;
}
.mat-badge {
  position: relative;
}
.mat-badge-hidden .mat-badge-content {
  display: none;
}
.mat-badge-disabled .mat-badge-content {
  background: #b9b9b9;
  color: rgba(0, 0, 0, 0.38);
}
.mat-badge-content {
  position: absolute;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  transition: transform 200ms ease-in-out;
  transform: scale(0.6);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
}
.ng-animate-disabled .mat-badge-content,
.mat-badge-content._mat-animation-noopable {
  transition: none;
}
.mat-badge-content.mat-badge-active {
  transform: none;
}
.mat-badge-small .mat-badge-content {
  width: 16px;
  height: 16px;
  line-height: 16px;
}
.mat-badge-small.mat-badge-above .mat-badge-content {
  top: -8px;
}
.mat-badge-small.mat-badge-below .mat-badge-content {
  bottom: -8px;
}
.mat-badge-small.mat-badge-before .mat-badge-content {
  left: -16px;
}
[dir=rtl] .mat-badge-small.mat-badge-before .mat-badge-content {
  left: auto;
  right: -16px;
}
.mat-badge-small.mat-badge-after .mat-badge-content {
  right: -16px;
}
[dir=rtl] .mat-badge-small.mat-badge-after .mat-badge-content {
  right: auto;
  left: -16px;
}
.mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -8px;
}
[dir=rtl] .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -8px;
}
.mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -8px;
}
[dir=rtl] .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -8px;
}
.mat-badge-medium .mat-badge-content {
  width: 22px;
  height: 22px;
  line-height: 22px;
}
.mat-badge-medium.mat-badge-above .mat-badge-content {
  top: -11px;
}
.mat-badge-medium.mat-badge-below .mat-badge-content {
  bottom: -11px;
}
.mat-badge-medium.mat-badge-before .mat-badge-content {
  left: -22px;
}
[dir=rtl] .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: auto;
  right: -22px;
}
.mat-badge-medium.mat-badge-after .mat-badge-content {
  right: -22px;
}
[dir=rtl] .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: auto;
  left: -22px;
}
.mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -11px;
}
[dir=rtl] .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -11px;
}
.mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -11px;
}
[dir=rtl] .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -11px;
}
.mat-badge-large .mat-badge-content {
  width: 28px;
  height: 28px;
  line-height: 28px;
}
.mat-badge-large.mat-badge-above .mat-badge-content {
  top: -14px;
}
.mat-badge-large.mat-badge-below .mat-badge-content {
  bottom: -14px;
}
.mat-badge-large.mat-badge-before .mat-badge-content {
  left: -28px;
}
[dir=rtl] .mat-badge-large.mat-badge-before .mat-badge-content {
  left: auto;
  right: -28px;
}
.mat-badge-large.mat-badge-after .mat-badge-content {
  right: -28px;
}
[dir=rtl] .mat-badge-large.mat-badge-after .mat-badge-content {
  right: auto;
  left: -28px;
}
.mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -14px;
}
[dir=rtl] .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -14px;
}
.mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -14px;
}
[dir=rtl] .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -14px;
}
.mat-bottom-sheet-container {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-button, .mat-icon-button, .mat-stroked-button {
  color: inherit;
  background: transparent;
}
.mat-button.mat-primary, .mat-icon-button.mat-primary, .mat-stroked-button.mat-primary {
  color: #3f51b5;
}
.mat-button.mat-accent, .mat-icon-button.mat-accent, .mat-stroked-button.mat-accent {
  color: #ff4081;
}
.mat-button.mat-warn, .mat-icon-button.mat-warn, .mat-stroked-button.mat-warn {
  color: #f44336;
}
.mat-button.mat-primary.mat-button-disabled, .mat-button.mat-accent.mat-button-disabled, .mat-button.mat-warn.mat-button-disabled, .mat-button.mat-button-disabled.mat-button-disabled, .mat-icon-button.mat-primary.mat-button-disabled, .mat-icon-button.mat-accent.mat-button-disabled, .mat-icon-button.mat-warn.mat-button-disabled, .mat-icon-button.mat-button-disabled.mat-button-disabled, .mat-stroked-button.mat-primary.mat-button-disabled, .mat-stroked-button.mat-accent.mat-button-disabled, .mat-stroked-button.mat-warn.mat-button-disabled, .mat-stroked-button.mat-button-disabled.mat-button-disabled {
  color: rgba(0, 0, 0, 0.26);
}
.mat-button.mat-primary .mat-button-focus-overlay, .mat-icon-button.mat-primary .mat-button-focus-overlay, .mat-stroked-button.mat-primary .mat-button-focus-overlay {
  background-color: #3f51b5;
}
.mat-button.mat-accent .mat-button-focus-overlay, .mat-icon-button.mat-accent .mat-button-focus-overlay, .mat-stroked-button.mat-accent .mat-button-focus-overlay {
  background-color: #ff4081;
}
.mat-button.mat-warn .mat-button-focus-overlay, .mat-icon-button.mat-warn .mat-button-focus-overlay, .mat-stroked-button.mat-warn .mat-button-focus-overlay {
  background-color: #f44336;
}
.mat-button.mat-button-disabled .mat-button-focus-overlay, .mat-icon-button.mat-button-disabled .mat-button-focus-overlay, .mat-stroked-button.mat-button-disabled .mat-button-focus-overlay {
  background-color: transparent;
}
.mat-button .mat-ripple-element, .mat-icon-button .mat-ripple-element, .mat-stroked-button .mat-ripple-element {
  opacity: 0.1;
  background-color: currentColor;
}
.mat-button-focus-overlay {
  background: black;
}
.mat-stroked-button:not(.mat-button-disabled) {
  border-color: rgba(0, 0, 0, 0.12);
}
.mat-flat-button, .mat-raised-button, .mat-fab, .mat-mini-fab {
  color: rgba(0, 0, 0, 0.87);
  background-color: white;
}
.mat-flat-button.mat-primary, .mat-raised-button.mat-primary, .mat-fab.mat-primary, .mat-mini-fab.mat-primary {
  color: white;
}
.mat-flat-button.mat-accent, .mat-raised-button.mat-accent, .mat-fab.mat-accent, .mat-mini-fab.mat-accent {
  color: white;
}
.mat-flat-button.mat-warn, .mat-raised-button.mat-warn, .mat-fab.mat-warn, .mat-mini-fab.mat-warn {
  color: white;
}
.mat-flat-button.mat-primary.mat-button-disabled, .mat-flat-button.mat-accent.mat-button-disabled, .mat-flat-button.mat-warn.mat-button-disabled, .mat-flat-button.mat-button-disabled.mat-button-disabled, .mat-raised-button.mat-primary.mat-button-disabled, .mat-raised-button.mat-accent.mat-button-disabled, .mat-raised-button.mat-warn.mat-button-disabled, .mat-raised-button.mat-button-disabled.mat-button-disabled, .mat-fab.mat-primary.mat-button-disabled, .mat-fab.mat-accent.mat-button-disabled, .mat-fab.mat-warn.mat-button-disabled, .mat-fab.mat-button-disabled.mat-button-disabled, .mat-mini-fab.mat-primary.mat-button-disabled, .mat-mini-fab.mat-accent.mat-button-disabled, .mat-mini-fab.mat-warn.mat-button-disabled, .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  color: rgba(0, 0, 0, 0.26);
}
.mat-flat-button.mat-primary, .mat-raised-button.mat-primary, .mat-fab.mat-primary, .mat-mini-fab.mat-primary {
  background-color: #3f51b5;
}
.mat-flat-button.mat-accent, .mat-raised-button.mat-accent, .mat-fab.mat-accent, .mat-mini-fab.mat-accent {
  background-color: #ff4081;
}
.mat-flat-button.mat-warn, .mat-raised-button.mat-warn, .mat-fab.mat-warn, .mat-mini-fab.mat-warn {
  background-color: #f44336;
}
.mat-flat-button.mat-primary.mat-button-disabled, .mat-flat-button.mat-accent.mat-button-disabled, .mat-flat-button.mat-warn.mat-button-disabled, .mat-flat-button.mat-button-disabled.mat-button-disabled, .mat-raised-button.mat-primary.mat-button-disabled, .mat-raised-button.mat-accent.mat-button-disabled, .mat-raised-button.mat-warn.mat-button-disabled, .mat-raised-button.mat-button-disabled.mat-button-disabled, .mat-fab.mat-primary.mat-button-disabled, .mat-fab.mat-accent.mat-button-disabled, .mat-fab.mat-warn.mat-button-disabled, .mat-fab.mat-button-disabled.mat-button-disabled, .mat-mini-fab.mat-primary.mat-button-disabled, .mat-mini-fab.mat-accent.mat-button-disabled, .mat-mini-fab.mat-warn.mat-button-disabled, .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  background-color: rgba(0, 0, 0, 0.12);
}
.mat-flat-button.mat-primary .mat-ripple-element, .mat-raised-button.mat-primary .mat-ripple-element, .mat-fab.mat-primary .mat-ripple-element, .mat-mini-fab.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.mat-flat-button.mat-accent .mat-ripple-element, .mat-raised-button.mat-accent .mat-ripple-element, .mat-fab.mat-accent .mat-ripple-element, .mat-mini-fab.mat-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.mat-flat-button.mat-warn .mat-ripple-element, .mat-raised-button.mat-warn .mat-ripple-element, .mat-fab.mat-warn .mat-ripple-element, .mat-mini-fab.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.mat-stroked-button:not([class*=mat-elevation-z]), .mat-flat-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.mat-raised-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.mat-raised-button:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.mat-raised-button.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.mat-fab:not([class*=mat-elevation-z]), .mat-mini-fab:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.mat-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]), .mat-mini-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.mat-fab.mat-button-disabled:not([class*=mat-elevation-z]), .mat-mini-fab.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.mat-button-toggle-standalone,
.mat-button-toggle-group {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.mat-button-toggle-group-appearance-standard {
  box-shadow: none;
}
.mat-button-toggle {
  color: rgba(0, 0, 0, 0.38);
}
.mat-button-toggle .mat-button-toggle-focus-overlay {
  background-color: rgba(0, 0, 0, 0.12);
}
.mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
  background: white;
}
.mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  background-color: black;
}
.mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
}
[dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid 1px rgba(0, 0, 0, 0.12);
}
.mat-button-toggle-checked {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.54);
}
.mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
}
.mat-button-toggle-disabled {
  color: rgba(0, 0, 0, 0.26);
  background-color: #eeeeee;
}
.mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
  background: white;
}
.mat-button-toggle-disabled.mat-button-toggle-checked {
  background-color: #bdbdbd;
}
.mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.mat-button-toggle-group-appearance-standard {
  border: solid 1px rgba(0, 0, 0, 0.12);
}
.mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
  line-height: 48px;
}
.mat-card {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-card:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.mat-card.mat-card-flat:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.mat-card-subtitle {
  color: rgba(0, 0, 0, 0.54);
}
.mat-checkbox-frame {
  border-color: rgba(0, 0, 0, 0.54);
}
.mat-checkbox-checkmark {
  fill: #fafafa;
}
.mat-checkbox-checkmark-path {
  stroke: #fafafa !important;
}
.mat-checkbox-mixedmark {
  background-color: #fafafa;
}
.mat-checkbox-indeterminate.mat-primary .mat-checkbox-background, .mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background-color: #3f51b5;
}
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #ff4081;
}
.mat-checkbox-indeterminate.mat-warn .mat-checkbox-background, .mat-checkbox-checked.mat-warn .mat-checkbox-background {
  background-color: #f44336;
}
.mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background, .mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: #b0b0b0;
}
.mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame {
  border-color: #b0b0b0;
}
.mat-checkbox-disabled .mat-checkbox-label {
  color: rgba(0, 0, 0, 0.54);
}
.mat-checkbox .mat-ripple-element {
  background-color: black;
}
.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element {
  background: #3f51b5;
}
.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
  background: #ff4081;
}
.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element {
  background: #f44336;
}
.mat-chip.mat-standard-chip {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.87);
}
.mat-chip.mat-standard-chip .mat-chip-remove {
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.4;
}
.mat-chip.mat-standard-chip:not(.mat-chip-disabled):active {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.mat-chip.mat-standard-chip:not(.mat-chip-disabled) .mat-chip-remove:hover {
  opacity: 0.54;
}
.mat-chip.mat-standard-chip.mat-chip-disabled {
  opacity: 0.4;
}
.mat-chip.mat-standard-chip::after {
  background: black;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
  background-color: #3f51b5;
  color: white;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-warn {
  background-color: #f44336;
  color: white;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-accent {
  background-color: #ff4081;
  color: white;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.mat-table {
  background: white;
}
.mat-table thead, .mat-table tbody, .mat-table tfoot,
mat-header-row, mat-row, mat-footer-row,
[mat-header-row], [mat-row], [mat-footer-row],
.mat-table-sticky {
  background: inherit;
}
mat-row, mat-header-row, mat-footer-row,
th.mat-header-cell, td.mat-cell, td.mat-footer-cell {
  border-bottom-color: rgba(0, 0, 0, 0.12);
}
.mat-header-cell {
  color: rgba(0, 0, 0, 0.54);
}
.mat-cell, .mat-footer-cell {
  color: rgba(0, 0, 0, 0.87);
}
.mat-calendar-arrow {
  border-top-color: rgba(0, 0, 0, 0.54);
}
.mat-datepicker-toggle,
.mat-datepicker-content .mat-calendar-next-button,
.mat-datepicker-content .mat-calendar-previous-button {
  color: rgba(0, 0, 0, 0.54);
}
.mat-calendar-table-header {
  color: rgba(0, 0, 0, 0.38);
}
.mat-calendar-table-header-divider::after {
  background: rgba(0, 0, 0, 0.12);
}
.mat-calendar-body-label {
  color: rgba(0, 0, 0, 0.54);
}
.mat-calendar-body-cell-content,
.mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.87);
  border-color: transparent;
}
.mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: rgba(0, 0, 0, 0.38);
}
.mat-form-field-disabled .mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.38);
}
.mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(0, 0, 0, 0.04);
}
.mat-calendar-body-in-preview {
  color: rgba(0, 0, 0, 0.24);
}
.mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.38);
}
.mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.18);
}
.mat-calendar-body-in-range::before {
  background: rgba(63, 81, 181, 0.2);
}
.mat-calendar-body-comparison-identical,
.mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.mat-calendar-body-comparison-bridge-start::before,
[dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(63, 81, 181, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-calendar-body-comparison-bridge-end::before,
[dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(63, 81, 181, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.mat-calendar-body-selected {
  background-color: #3f51b5;
  color: white;
}
.mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(63, 81, 181, 0.4);
}
.mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before {
  background: rgba(255, 64, 129, 0.2);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
.mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(255, 64, 129, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
.mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(255, 64, 129, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: #ff4081;
  color: white;
}
.mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(255, 64, 129, 0.4);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before {
  background: rgba(244, 67, 54, 0.2);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
.mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(244, 67, 54, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
.mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(244, 67, 54, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: #f44336;
  color: white;
}
.mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(244, 67, 54, 0.4);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.mat-datepicker-content-touch {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.mat-datepicker-toggle-active {
  color: #3f51b5;
}
.mat-datepicker-toggle-active.mat-accent {
  color: #ff4081;
}
.mat-datepicker-toggle-active.mat-warn {
  color: #f44336;
}
.mat-date-range-input-inner[disabled] {
  color: rgba(0, 0, 0, 0.38);
}
.mat-dialog-container {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-divider {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.mat-divider-vertical {
  border-right-color: rgba(0, 0, 0, 0.12);
}
.mat-expansion-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.mat-action-row {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: rgba(0, 0, 0, 0.04);
}
@media (hover: none) {
  .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background: white;
  }
}
.mat-expansion-panel-header-title {
  color: rgba(0, 0, 0, 0.87);
}
.mat-expansion-panel-header-description,
.mat-expansion-indicator::after {
  color: rgba(0, 0, 0, 0.54);
}
.mat-expansion-panel-header[aria-disabled=true] {
  color: rgba(0, 0, 0, 0.26);
}
.mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
.mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
  color: inherit;
}
.mat-expansion-panel-header {
  height: 48px;
}
.mat-expansion-panel-header.mat-expanded {
  height: 64px;
}
.mat-form-field-label {
  color: rgba(0, 0, 0, 0.6);
}
.mat-hint {
  color: rgba(0, 0, 0, 0.6);
}
.mat-form-field.mat-focused .mat-form-field-label {
  color: #3f51b5;
}
.mat-form-field.mat-focused .mat-form-field-label.mat-accent {
  color: #ff4081;
}
.mat-form-field.mat-focused .mat-form-field-label.mat-warn {
  color: #f44336;
}
.mat-focused .mat-form-field-required-marker {
  color: #ff4081;
}
.mat-form-field-ripple {
  background-color: rgba(0, 0, 0, 0.87);
}
.mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: #3f51b5;
}
.mat-form-field.mat-focused .mat-form-field-ripple.mat-accent {
  background-color: #ff4081;
}
.mat-form-field.mat-focused .mat-form-field-ripple.mat-warn {
  background-color: #f44336;
}
.mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid) .mat-form-field-infix::after {
  color: #3f51b5;
}
.mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-accent .mat-form-field-infix::after {
  color: #ff4081;
}
.mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-warn .mat-form-field-infix::after {
  color: #f44336;
}
.mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color: #f44336;
}
.mat-form-field.mat-form-field-invalid .mat-form-field-label.mat-accent,
.mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker {
  color: #f44336;
}
.mat-form-field.mat-form-field-invalid .mat-form-field-ripple,
.mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent {
  background-color: #f44336;
}
.mat-error {
  color: #f44336;
}
.mat-form-field-appearance-legacy .mat-form-field-label {
  color: rgba(0, 0, 0, 0.54);
}
.mat-form-field-appearance-legacy .mat-hint {
  color: rgba(0, 0, 0, 0.54);
}
.mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.mat-form-field-appearance-standard .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.mat-form-field-appearance-standard.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.04);
}
.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.02);
}
.mat-form-field-appearance-fill .mat-form-field-underline::before {
  background-color: rgba(0, 0, 0, 0.42);
}
.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-underline::before {
  background-color: transparent;
}
.mat-form-field-appearance-outline .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.12);
}
.mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: rgba(0, 0, 0, 0.87);
}
.mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: #3f51b5;
}
.mat-form-field-appearance-outline.mat-focused.mat-accent .mat-form-field-outline-thick {
  color: #ff4081;
}
.mat-form-field-appearance-outline.mat-focused.mat-warn .mat-form-field-outline-thick {
  color: #f44336;
}
.mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
  color: #f44336;
}
.mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.06);
}
.mat-icon.mat-primary {
  color: #3f51b5;
}
.mat-icon.mat-accent {
  color: #ff4081;
}
.mat-icon.mat-warn {
  color: #f44336;
}
.mat-form-field-type-mat-native-select .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.54);
}
.mat-input-element:disabled,
.mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.38);
}
.mat-input-element {
  caret-color: #3f51b5;
}
.mat-input-element::-moz-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.mat-input-element::placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.mat-input-element::-moz-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.mat-input-element::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.mat-input-element:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.mat-form-field.mat-accent .mat-input-element {
  caret-color: #ff4081;
}
.mat-form-field.mat-warn .mat-input-element,
.mat-form-field-invalid .mat-input-element {
  caret-color: #f44336;
}
.mat-form-field-type-mat-native-select.mat-form-field-invalid .mat-form-field-infix::after {
  color: #f44336;
}
.mat-list-base .mat-list-item {
  color: rgba(0, 0, 0, 0.87);
}
.mat-list-base .mat-list-option {
  color: rgba(0, 0, 0, 0.87);
}
.mat-list-base .mat-subheader {
  color: rgba(0, 0, 0, 0.54);
}
.mat-list-item-disabled {
  background-color: #eeeeee;
}
.mat-list-option:hover, .mat-list-option:focus,
.mat-nav-list .mat-list-item:hover,
.mat-nav-list .mat-list-item:focus,
.mat-action-list .mat-list-item:hover,
.mat-action-list .mat-list-item:focus {
  background: rgba(0, 0, 0, 0.04);
}
.mat-list-single-selected-option, .mat-list-single-selected-option:hover, .mat-list-single-selected-option:focus {
  background: rgba(0, 0, 0, 0.12);
}
.mat-menu-panel {
  background: white;
}
.mat-menu-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.mat-menu-item {
  background: transparent;
  color: rgba(0, 0, 0, 0.87);
}
.mat-menu-item[disabled], .mat-menu-item[disabled]::after {
  color: rgba(0, 0, 0, 0.38);
}
.mat-menu-item .mat-icon-no-color,
.mat-menu-item-submenu-trigger::after {
  color: rgba(0, 0, 0, 0.54);
}
.mat-menu-item:hover:not([disabled]),
.mat-menu-item.cdk-program-focused:not([disabled]),
.mat-menu-item.cdk-keyboard-focused:not([disabled]),
.mat-menu-item-highlighted:not([disabled]) {
  background: rgba(0, 0, 0, 0.04);
}
.mat-paginator {
  background: white;
}
.mat-paginator,
.mat-paginator-page-size .mat-select-trigger {
  color: rgba(0, 0, 0, 0.54);
}
.mat-paginator-decrement,
.mat-paginator-increment {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
  border-right: 2px solid rgba(0, 0, 0, 0.54);
}
.mat-paginator-first,
.mat-paginator-last {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
}
.mat-icon-button[disabled] .mat-paginator-decrement,
.mat-icon-button[disabled] .mat-paginator-increment,
.mat-icon-button[disabled] .mat-paginator-first,
.mat-icon-button[disabled] .mat-paginator-last {
  border-color: rgba(0, 0, 0, 0.38);
}
.mat-paginator-container {
  min-height: 56px;
}
.mat-progress-bar-background {
  fill: #c5cae9;
}
.mat-progress-bar-buffer {
  background-color: #c5cae9;
}
.mat-progress-bar-fill::after {
  background-color: #3f51b5;
}
.mat-progress-bar.mat-accent .mat-progress-bar-background {
  fill: #ff80ab;
}
.mat-progress-bar.mat-accent .mat-progress-bar-buffer {
  background-color: #ff80ab;
}
.mat-progress-bar.mat-accent .mat-progress-bar-fill::after {
  background-color: #ff4081;
}
.mat-progress-bar.mat-warn .mat-progress-bar-background {
  fill: #ffcdd2;
}
.mat-progress-bar.mat-warn .mat-progress-bar-buffer {
  background-color: #ffcdd2;
}
.mat-progress-bar.mat-warn .mat-progress-bar-fill::after {
  background-color: #f44336;
}
.mat-progress-spinner circle, .mat-spinner circle {
  stroke: #3f51b5;
}
.mat-progress-spinner.mat-accent circle, .mat-spinner.mat-accent circle {
  stroke: #ff4081;
}
.mat-progress-spinner.mat-warn circle, .mat-spinner.mat-warn circle {
  stroke: #f44336;
}
.mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.54);
}
.mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
  border-color: #3f51b5;
}
.mat-radio-button.mat-primary .mat-radio-inner-circle,
.mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-primary:active .mat-radio-persistent-ripple {
  background-color: #3f51b5;
}
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #ff4081;
}
.mat-radio-button.mat-accent .mat-radio-inner-circle,
.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #ff4081;
}
.mat-radio-button.mat-warn.mat-radio-checked .mat-radio-outer-circle {
  border-color: #f44336;
}
.mat-radio-button.mat-warn .mat-radio-inner-circle,
.mat-radio-button.mat-warn .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-warn:active .mat-radio-persistent-ripple {
  background-color: #f44336;
}
.mat-radio-button.mat-radio-disabled.mat-radio-checked .mat-radio-outer-circle,
.mat-radio-button.mat-radio-disabled .mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.38);
}
.mat-radio-button.mat-radio-disabled .mat-radio-ripple .mat-ripple-element,
.mat-radio-button.mat-radio-disabled .mat-radio-inner-circle {
  background-color: rgba(0, 0, 0, 0.38);
}
.mat-radio-button.mat-radio-disabled .mat-radio-label-content {
  color: rgba(0, 0, 0, 0.38);
}
.mat-radio-button .mat-ripple-element {
  background-color: black;
}
.mat-select-value {
  color: rgba(0, 0, 0, 0.87);
}
.mat-select-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.mat-select-disabled .mat-select-value {
  color: rgba(0, 0, 0, 0.38);
}
.mat-select-arrow {
  color: rgba(0, 0, 0, 0.54);
}
.mat-select-panel {
  background: white;
}
.mat-select-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background: rgba(0, 0, 0, 0.12);
}
.mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: #3f51b5;
}
.mat-form-field.mat-focused.mat-accent .mat-select-arrow {
  color: #ff4081;
}
.mat-form-field.mat-focused.mat-warn .mat-select-arrow {
  color: #f44336;
}
.mat-form-field .mat-select.mat-select-invalid .mat-select-arrow {
  color: #f44336;
}
.mat-form-field .mat-select.mat-select-disabled .mat-select-arrow {
  color: rgba(0, 0, 0, 0.38);
}
.mat-drawer-container {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}
.mat-drawer {
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-drawer.mat-drawer-push {
  background-color: white;
}
.mat-drawer:not(.mat-drawer-side) {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.mat-drawer-side {
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.mat-drawer-side.mat-drawer-end {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
[dir=rtl] .mat-drawer-side {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
[dir=rtl] .mat-drawer-side.mat-drawer-end {
  border-left: none;
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(0, 0, 0, 0.6);
}
.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #ff4081;
}
.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(255, 64, 129, 0.54);
}
.mat-slide-toggle.mat-checked .mat-ripple-element {
  background-color: #ff4081;
}
.mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
  background-color: #3f51b5;
}
.mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(63, 81, 181, 0.54);
}
.mat-slide-toggle.mat-primary.mat-checked .mat-ripple-element {
  background-color: #3f51b5;
}
.mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-thumb {
  background-color: #f44336;
}
.mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(244, 67, 54, 0.54);
}
.mat-slide-toggle.mat-warn.mat-checked .mat-ripple-element {
  background-color: #f44336;
}
.mat-slide-toggle:not(.mat-checked) .mat-ripple-element {
  background-color: black;
}
.mat-slide-toggle-thumb {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  background-color: #fafafa;
}
.mat-slide-toggle-bar {
  background-color: rgba(0, 0, 0, 0.38);
}
.mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}
.mat-primary .mat-slider-track-fill,
.mat-primary .mat-slider-thumb,
.mat-primary .mat-slider-thumb-label {
  background-color: #3f51b5;
}
.mat-primary .mat-slider-thumb-label-text {
  color: white;
}
.mat-primary .mat-slider-focus-ring {
  background-color: rgba(63, 81, 181, 0.2);
}
.mat-accent .mat-slider-track-fill,
.mat-accent .mat-slider-thumb,
.mat-accent .mat-slider-thumb-label {
  background-color: #ff4081;
}
.mat-accent .mat-slider-thumb-label-text {
  color: white;
}
.mat-accent .mat-slider-focus-ring {
  background-color: rgba(255, 64, 129, 0.2);
}
.mat-warn .mat-slider-track-fill,
.mat-warn .mat-slider-thumb,
.mat-warn .mat-slider-thumb-label {
  background-color: #f44336;
}
.mat-warn .mat-slider-thumb-label-text {
  color: white;
}
.mat-warn .mat-slider-focus-ring {
  background-color: rgba(244, 67, 54, 0.2);
}
.mat-slider:hover .mat-slider-track-background,
.cdk-focused .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.38);
}
.mat-slider-disabled .mat-slider-track-background,
.mat-slider-disabled .mat-slider-track-fill,
.mat-slider-disabled .mat-slider-thumb {
  background-color: rgba(0, 0, 0, 0.26);
}
.mat-slider-disabled:hover .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}
.mat-slider-min-value .mat-slider-focus-ring {
  background-color: rgba(0, 0, 0, 0.12);
}
.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb,
.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.87);
}
.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb,
.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.26);
}
.mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
  background-color: transparent;
}
.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover .mat-slider-thumb, .mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.38);
}
.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover.mat-slider-disabled .mat-slider-thumb, .mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused.mat-slider-disabled .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
}
.mat-slider-has-ticks .mat-slider-wrapper::after {
  border-color: rgba(0, 0, 0, 0.7);
}
.mat-slider-horizontal .mat-slider-ticks {
  background-image: repeating-linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
  background-image: -moz-repeating-linear-gradient(0.0001deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}
.mat-slider-vertical .mat-slider-ticks {
  background-image: repeating-linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}
.mat-step-header.cdk-keyboard-focused, .mat-step-header.cdk-program-focused, .mat-step-header:hover {
  background-color: rgba(0, 0, 0, 0.04);
}
@media (hover: none) {
  .mat-step-header:hover {
    background: none;
  }
}
.mat-step-header .mat-step-label,
.mat-step-header .mat-step-optional {
  color: rgba(0, 0, 0, 0.54);
}
.mat-step-header .mat-step-icon {
  background-color: rgba(0, 0, 0, 0.54);
  color: white;
}
.mat-step-header .mat-step-icon-selected,
.mat-step-header .mat-step-icon-state-done,
.mat-step-header .mat-step-icon-state-edit {
  background-color: #3f51b5;
  color: white;
}
.mat-step-header .mat-step-icon-state-error {
  background-color: transparent;
  color: #f44336;
}
.mat-step-header .mat-step-label.mat-step-label-active {
  color: rgba(0, 0, 0, 0.87);
}
.mat-step-header .mat-step-label.mat-step-label-error {
  color: #f44336;
}
.mat-stepper-horizontal, .mat-stepper-vertical {
  background-color: white;
}
.mat-stepper-vertical-line::before {
  border-left-color: rgba(0, 0, 0, 0.12);
}
.mat-horizontal-stepper-header::before,
.mat-horizontal-stepper-header::after,
.mat-stepper-horizontal-line {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.mat-horizontal-stepper-header {
  height: 72px;
}
.mat-stepper-label-position-bottom .mat-horizontal-stepper-header,
.mat-vertical-stepper-header {
  padding: 24px 24px;
}
.mat-stepper-vertical-line::before {
  top: -16px;
  bottom: -16px;
}
.mat-stepper-label-position-bottom .mat-horizontal-stepper-header::after, .mat-stepper-label-position-bottom .mat-horizontal-stepper-header::before {
  top: 36px;
}
.mat-stepper-label-position-bottom .mat-stepper-horizontal-line {
  top: 36px;
}
.mat-sort-header-arrow {
  color: #757575;
}
.mat-tab-nav-bar,
.mat-tab-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.mat-tab-group-inverted-header .mat-tab-nav-bar,
.mat-tab-group-inverted-header .mat-tab-header {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom: none;
}
.mat-tab-label, .mat-tab-link {
  color: rgba(0, 0, 0, 0.87);
}
.mat-tab-label.mat-tab-disabled, .mat-tab-link.mat-tab-disabled {
  color: rgba(0, 0, 0, 0.38);
}
.mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.87);
}
.mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.38);
}
.mat-tab-group[class*=mat-background-] .mat-tab-header,
.mat-tab-nav-bar[class*=mat-background-] {
  border-bottom: none;
  border-top: none;
}
.mat-tab-group.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(197, 202, 233, 0.3);
}
.mat-tab-group.mat-primary .mat-ink-bar, .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #3f51b5;
}
.mat-tab-group.mat-primary.mat-background-primary .mat-ink-bar, .mat-tab-nav-bar.mat-primary.mat-background-primary .mat-ink-bar {
  background-color: white;
}
.mat-tab-group.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 128, 171, 0.3);
}
.mat-tab-group.mat-accent .mat-ink-bar, .mat-tab-nav-bar.mat-accent .mat-ink-bar {
  background-color: #ff4081;
}
.mat-tab-group.mat-accent.mat-background-accent .mat-ink-bar, .mat-tab-nav-bar.mat-accent.mat-background-accent .mat-ink-bar {
  background-color: white;
}
.mat-tab-group.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 205, 210, 0.3);
}
.mat-tab-group.mat-warn .mat-ink-bar, .mat-tab-nav-bar.mat-warn .mat-ink-bar {
  background-color: #f44336;
}
.mat-tab-group.mat-warn.mat-background-warn .mat-ink-bar, .mat-tab-nav-bar.mat-warn.mat-background-warn .mat-ink-bar {
  background-color: white;
}
.mat-tab-group.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(197, 202, 233, 0.3);
}
.mat-tab-group.mat-background-primary .mat-tab-header, .mat-tab-group.mat-background-primary .mat-tab-links, .mat-tab-group.mat-background-primary .mat-tab-header-pagination, .mat-tab-nav-bar.mat-background-primary .mat-tab-header, .mat-tab-nav-bar.mat-background-primary .mat-tab-links, .mat-tab-nav-bar.mat-background-primary .mat-tab-header-pagination {
  background-color: #3f51b5;
}
.mat-tab-group.mat-background-primary .mat-tab-label, .mat-tab-group.mat-background-primary .mat-tab-link, .mat-tab-nav-bar.mat-background-primary .mat-tab-label, .mat-tab-nav-bar.mat-background-primary .mat-tab-link {
  color: white;
}
.mat-tab-group.mat-background-primary .mat-tab-label.mat-tab-disabled, .mat-tab-group.mat-background-primary .mat-tab-link.mat-tab-disabled, .mat-tab-nav-bar.mat-background-primary .mat-tab-label.mat-tab-disabled, .mat-tab-nav-bar.mat-background-primary .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.mat-tab-group.mat-background-primary .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-primary .mat-tab-header-pagination-chevron {
  border-color: white;
}
.mat-tab-group.mat-background-primary .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-primary .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(255, 255, 255, 0.4);
}
.mat-tab-group.mat-background-primary .mat-ripple-element, .mat-tab-nav-bar.mat-background-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.12);
}
.mat-tab-group.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 128, 171, 0.3);
}
.mat-tab-group.mat-background-accent .mat-tab-header, .mat-tab-group.mat-background-accent .mat-tab-links, .mat-tab-group.mat-background-accent .mat-tab-header-pagination, .mat-tab-nav-bar.mat-background-accent .mat-tab-header, .mat-tab-nav-bar.mat-background-accent .mat-tab-links, .mat-tab-nav-bar.mat-background-accent .mat-tab-header-pagination {
  background-color: #ff4081;
}
.mat-tab-group.mat-background-accent .mat-tab-label, .mat-tab-group.mat-background-accent .mat-tab-link, .mat-tab-nav-bar.mat-background-accent .mat-tab-label, .mat-tab-nav-bar.mat-background-accent .mat-tab-link {
  color: white;
}
.mat-tab-group.mat-background-accent .mat-tab-label.mat-tab-disabled, .mat-tab-group.mat-background-accent .mat-tab-link.mat-tab-disabled, .mat-tab-nav-bar.mat-background-accent .mat-tab-label.mat-tab-disabled, .mat-tab-nav-bar.mat-background-accent .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.mat-tab-group.mat-background-accent .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-accent .mat-tab-header-pagination-chevron {
  border-color: white;
}
.mat-tab-group.mat-background-accent .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-accent .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(255, 255, 255, 0.4);
}
.mat-tab-group.mat-background-accent .mat-ripple-element, .mat-tab-nav-bar.mat-background-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.12);
}
.mat-tab-group.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 205, 210, 0.3);
}
.mat-tab-group.mat-background-warn .mat-tab-header, .mat-tab-group.mat-background-warn .mat-tab-links, .mat-tab-group.mat-background-warn .mat-tab-header-pagination, .mat-tab-nav-bar.mat-background-warn .mat-tab-header, .mat-tab-nav-bar.mat-background-warn .mat-tab-links, .mat-tab-nav-bar.mat-background-warn .mat-tab-header-pagination {
  background-color: #f44336;
}
.mat-tab-group.mat-background-warn .mat-tab-label, .mat-tab-group.mat-background-warn .mat-tab-link, .mat-tab-nav-bar.mat-background-warn .mat-tab-label, .mat-tab-nav-bar.mat-background-warn .mat-tab-link {
  color: white;
}
.mat-tab-group.mat-background-warn .mat-tab-label.mat-tab-disabled, .mat-tab-group.mat-background-warn .mat-tab-link.mat-tab-disabled, .mat-tab-nav-bar.mat-background-warn .mat-tab-label.mat-tab-disabled, .mat-tab-nav-bar.mat-background-warn .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.mat-tab-group.mat-background-warn .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-warn .mat-tab-header-pagination-chevron {
  border-color: white;
}
.mat-tab-group.mat-background-warn .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-warn .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(255, 255, 255, 0.4);
}
.mat-tab-group.mat-background-warn .mat-ripple-element, .mat-tab-nav-bar.mat-background-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.12);
}
.mat-toolbar {
  background: whitesmoke;
  color: rgba(0, 0, 0, 0.87);
}
.mat-toolbar.mat-primary {
  background: #3f51b5;
  color: white;
}
.mat-toolbar.mat-accent {
  background: #ff4081;
  color: white;
}
.mat-toolbar.mat-warn {
  background: #f44336;
  color: white;
}
.mat-toolbar .mat-form-field-underline,
.mat-toolbar .mat-form-field-ripple,
.mat-toolbar .mat-focused .mat-form-field-ripple {
  background-color: currentColor;
}
.mat-toolbar .mat-form-field-label,
.mat-toolbar .mat-focused .mat-form-field-label,
.mat-toolbar .mat-select-value,
.mat-toolbar .mat-select-arrow,
.mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
  color: inherit;
}
.mat-toolbar .mat-input-element {
  caret-color: currentColor;
}
.mat-toolbar-multiple-rows {
  min-height: 64px;
}
.mat-toolbar-row, .mat-toolbar-single-row {
  height: 64px;
}
@media (max-width: 599px) {
  .mat-toolbar-multiple-rows {
    min-height: 56px;
  }

  .mat-toolbar-row, .mat-toolbar-single-row {
    height: 56px;
  }
}
.mat-tooltip {
  background: rgba(97, 97, 97, 0.9);
}
.mat-tree {
  background: white;
}
.mat-tree-node,
.mat-nested-tree-node {
  color: rgba(0, 0, 0, 0.87);
}
.mat-tree-node {
  min-height: 48px;
}
.mat-snack-bar-container {
  color: rgba(255, 255, 255, 0.7);
  background: #323232;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.mat-simple-snackbar-action {
  color: #ff4081;
}
.custom-mat-invalid-style mat-label,
.custom-mat-invalid-style p {
  color: red;
}
.custom-mat-invalid-style .mat-form-field-underline {
  background-color: red;
}
.custom-mat-invalid-style .mat-form-field-underline {
  background-color: red;
}
.custom-mat-invalid-style .mat-radio-outer-circle {
  border-color: red;
}
.custom-mat-invalid-style .mat-radio-button {
  color: red;
}
.mat-radio-button.mat-accent .mat-radio-inner-circle {
  background-color: #0068DC !important;
}
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #0068DC !important;
}
.button {
  display: block;
  font-size: 16px;
  border-radius: 4px;
  border: none;
  text-align: center;
  line-height: 20px;
  width: 100%;
  padding: 16px 0;
  cursor: pointer;
  background-color: #FFFFFF;
  font-weight: 300;
  font-family: "Chivo", sans-serif;
}
@media screen and (min-width: 961px) {
  .button {
    width: 300px;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
}
.button-blue {
  background-color: #0256FF;
  color: #FFFFFF;
}
.button-black {
  background-color: #2d2d2d;
  color: #FFFFFF;
}
.button-red {
  background-color: red;
  color: #FFFFFF;
}
.button-dark-blue {
  background-color: #3E0CEF;
  color: #FFFFFF;
}
.button-gray {
  background-color: #CACACA;
  color: #6c727c;
}
.button-dark-gray {
  background-color: #6c727c;
  color: #ffffff;
}
.button-gray-outline {
  border: 2px solid #CACACA;
  color: #6c727c;
}
.one-line {
  display: flex;
}
@media screen and (min-width: 961px) {
  .one-line.center-dp {
    justify-content: center;
  }
}
.one-line > * {
  margin-right: 10%;
}
.one-line > *:last-child {
  margin-right: 0;
}
@media screen and (min-width: 961px) {
  .one-line > *:first-child {
    margin-left: 0;
  }
}
[class^=icon-],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-add-file:before {
  content: "";
}
.icon-edit-border .path1:before {
  content: "";
  color: #0256ff;
}
.icon-bank-accounts:before {
  content: "";
}
.icon-financial-transactions:before {
  content: "";
}
.icon-invoices:before {
  content: "";
}
.icon-logout:before {
  content: "";
}
.icon-my-investments:before {
  content: "";
}
.icon-opportunities:before {
  content: "";
}
.icon-profile:before {
  content: "";
}
.icon-system-settings:before {
  content: "";
}
.icon-user-accounts:before {
  content: "";
}
.icon-retention:before {
  content: "";
  color: #6c727c;
}
.icon-excel:before {
  content: "";
  color: #6c727c;
}
.icon-price_change:before {
  content: "";
  color: #6c727c;
}
.icon-history:before {
  content: "";
  color: #6c727c;
}
.icon-settings:before {
  content: "";
  color: #6c727c;
}
.icon-stacked-bar:before {
  content: "";
  color: #6c727c;
}
.icon-receipt:before {
  content: "";
  color: #0256ff;
}
.icon-publish:before {
  content: "";
  color: #0256ff;
}
.icon-not_published:before {
  content: "";
  color: #6c727c;
}
.icon-funnel:before {
  content: "";
}
.icon-company:before {
  content: "";
}
.icon-pending_action:before {
  content: "";
}
.icon-event:before {
  content: "";
}
.icon-donut:before {
  content: "";
}
.icon-receipt_long:before {
  content: "";
}
.icon-assignment:before {
  content: "";
}
.icon-leaderboard:before {
  content: "";
}
.icon-pending:before {
  content: "";
}
.icon-arrow_forward:before {
  content: "";
}
.icon-download:before {
  content: "";
}
.icon-timeline:before {
  content: "";
}
.icon-savings:before {
  content: "";
}
.icon-arrow_left:before {
  content: "";
}
.icon-arrow_right:before {
  content: "";
}
.icon-annoucement:before {
  content: "";
}
.icon-mail:before {
  content: "";
}
.icon-funnel_filled:before {
  content: "";
}
.icon-company_filled:before {
  content: "";
}
.icon-schedule:before {
  content: "";
}
.icon-for_sale:before {
  content: "";
}
.icon-info:before {
  content: "";
}
.icon-today_calendar:before {
  content: "";
  color: #6c727c;
}
.icon-waving_hand:before {
  content: "";
  color: #6c727c;
}
.icon-attach_file:before {
  content: "";
  color: #6c727c;
}
.icon-content_paste:before {
  content: "";
  color: #fff;
}
.icon-point_sale:before {
  content: "";
  color: #6c727c;
}
.icon-settings_normal:before {
  content: "";
}
.icon-description:before {
  content: "";
}
.icon-help_info:before {
  content: "";
}
.icon-double_arrow_down:before {
  content: "";
  color: #fff;
}
.icon-fx_formula:before {
  content: "";
}
.icon-article:before {
  content: "";
}
.icon-save:before {
  content: "";
}
.icon-content_copy:before {
  content: "";
}
.icon-cartera:before {
  content: "";
}
.icon-more_horiz:before {
  content: "";
}
.icon-open_new:before {
  content: "";
}
.icon-filter_list:before {
  content: "";
}
.icon-check-box-empty:before {
  content: "";
  color: #6c727c;
}
.icon-check-box-approved:before {
  content: "";
  color: #12ab64;
}
.icon-check-box-denied:before {
  content: "";
  color: #ef4444;
}
.icon-check-box-approve-filled:before {
  content: "";
  color: #6c727c;
}
.icon-date-picker:before {
  content: "";
  color: #6c727c;
}
.icon-type-number:before {
  content: "";
  color: #6c727c;
}
.icon-type-operation:before {
  content: "";
  color: #6c727c;
}
.icon-type-text:before {
  content: "";
  color: #6c727c;
}
.icon-check-box-pending:before {
  content: "";
  color: #ffc400;
}
.icon-delete:before {
  content: "";
}
.icon-add:before {
  content: "";
}
.icon-observations:before {
  content: "";
}
.icon-users:before {
  content: "";
}
.icon-business:before {
  content: "";
}
.icon-telegram:before {
  content: "";
}
.icon-visibility_black:before {
  content: "";
}
.icon-note_add_black:before {
  content: "";
}
.icon-comments:before {
  content: "";
  color: #fff;
}
.icon-edit:before {
  content: "";
}
.icon-cancel:before {
  content: "";
}
.icon-visibility_off_black:before {
  content: "";
}
.icon-person:before {
  content: "";
  color: #fff;
}
.icon-contacts:before {
  content: "";
}
.icon-autorenew_black:before {
  content: "";
}
.icon-add-user:before {
  content: "";
  color: #fff;
}
.icon-check_circle:before {
  content: "";
}
.icon-fullscreen_black:before {
  content: "";
}
.icon-download_off:before {
  content: "";
}
.icon-grouping:before {
  content: "";
}
.icon-upload_icon:before {
  content: "";
}
.icon-post_add:before {
  content: "";
}
.icon-delete_icon_filled:before {
  content: "";
}
.icon-circle-yellow:before {
  content: "";
  color: #ffc400;
}
.icon-circle-gray:before {
  content: "";
}
.icon-circle-red:before {
  content: "";
  color: #ef4444;
}
.icon-circle-green:before {
  content: "";
  color: #2eb67d;
}
.icon-arrow_down:before {
  content: "";
}
.icon-warning:before {
  content: "";
  color: #ffc400;
}
.icon-done_black:before {
  content: "";
}
.icon-warning-rounded:before {
  content: "";
  color: #f00;
}
.icon-arrow-right:before {
  content: "";
}
.icon-close:before {
  content: "";
}
.icon-approved:before {
  content: "";
  color: #12ab64;
}
.icon-ws-outline:before {
  content: "";
}
.icon-ws-filled:before {
  content: "";
}
.icon-denied:before {
  content: "";
  color: #f00;
}
.icon-todo-check:before {
  content: "";
  color: #6c727c;
}
.icon-money:before {
  content: "";
  color: #0256ff;
}
.icon-accepted:before {
  content: "";
  color: #12ab64;
}
.icon-Undo:before {
  content: "";
  color: #0256ff;
}
.icon-pending-check:before {
  content: "";
  color: #ffc400;
}
.icon-investment:before {
  content: "";
  color: #0256ff;
}
.icon-thumb-down:before {
  content: "";
}
.icon-thumb-up:before {
  content: "";
}
.icon-Star-border:before {
  content: "";
}
.icon-Star:before {
  content: "";
}
.icon-store:before {
  content: "";
  color: #0256ff;
  font-size: 24px !important;
}
.audio-progress-bar {
  /* remove animation and the dots*/
}
.audio-progress-bar.mat-progress-bar {
  border-radius: 5px;
  height: 6px;
  margin-bottom: 5px;
}
.audio-progress-bar .mat-progress-bar-fill::after {
  background-color: #0068DC;
}
.audio-progress-bar .mat-progress-bar-buffer {
  background-color: #bdc4c7;
}
.audio-progress-bar .mat-progress-bar-background {
  -webkit-animation: none;
          animation: none;
  background-color: #eceff1;
  fill: #eceff1;
}
lib-rating .rating {
  margin-top: 1px !important;
}
h1 {
  font-size: 22px;
}
.underline {
  text-decoration: underline;
}
.dark-backdrop {
  background-color: #ffffff;
}
.full-width {
  width: 100%;
  padding: 0 6.42%;
  padding-top: 20px;
  padding-bottom: 20px;
}
.reset-display {
  display: initial !important;
}
.tiny-logo {
  width: 28px;
}
.tiny {
  font-size: 12px;
  font-weight: 300;
}
.tiny-title {
  margin-bottom: 4px;
  display: block;
}
.big {
  font-size: 21px;
  color: #6c727c;
  font-weight: 500;
}
hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
.title-opportunities {
  margin-bottom: 10px;
}
.link-active {
  color: #0068DC;
  font-weight: 500;
}
.full-width {
  overflow-y: scroll;
}
.icon-button,
.action-icon {
  cursor: pointer;
}
.blue {
  color: #0256FF;
}
.bold {
  font-weight: 500;
}
.no-items-found {
  color: #6c727c;
  font-size: 20px;
  text-align: center;
  margin: 60px 0;
}
.tooltip-container {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
}
.tooltip-container-not-dots {
  position: relative;
  display: inline-block;
}
.tooltip-container-not-dots .tooltiptext {
  visibility: hidden;
  word-wrap: break-word;
  width: 120px;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  background-color: #313131;
  font-size: 10px;
  /* Position the tooltip */
  position: absolute;
  z-index: 2;
}
.tooltip-container-not-dots .tooltiptext-left {
  visibility: hidden;
  width: 130px;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  background-color: #313131;
  font-size: 10px;
  word-wrap: break-word;
  white-space: pre;
  /* Position the tooltip */
  position: absolute;
  z-index: 2;
}
.tooltip-container-not-dots .tooltiptext-right2 {
  visibility: hidden;
  width: 130px;
  color: #fff;
  text-align: center !important;
  border-radius: 6px;
  padding: 5px;
  background-color: #313131;
  font-size: 10px;
  word-wrap: break-word;
  /* Position the tooltip */
  position: absolute;
  margin-left: 10px;
  z-index: 2;
}
.tooltip-container .tooltiptext {
  visibility: hidden;
  width: 120px;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  background-color: #313131;
  font-size: 10px;
  word-wrap: break-word;
  /* Position the tooltip */
  position: absolute;
  z-index: 2;
}
.tooltip-container .tooltiptext-right {
  visibility: hidden;
  width: 120px;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  background-color: #313131;
  font-size: 10px;
  word-wrap: break-word;
  /* Position the tooltip */
  position: absolute;
  z-index: 2;
  top: 100%;
  left: 50%;
  margin-left: -120px;
}
.tooltip-container-not-dots .tooltiptext-right {
  visibility: hidden;
  width: 120px;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  background-color: #313131;
  font-size: 10px;
  word-wrap: break-word;
  /* Position the tooltip */
  position: absolute;
  z-index: 100;
  top: 100%;
  left: 50%;
  margin-left: -120px;
}
.tooltip-container-not-dots .tooltiptext-bottom {
  visibility: hidden;
  width: 120px;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  background-color: #313131;
  font-size: 10px;
  /* Position the tooltip */
  position: absolute;
  word-wrap: break-word;
  z-index: 100;
  top: 100%;
  left: 50%;
  margin-left: -60px;
}
.tooltip-container:hover .tooltiptext,
.tooltiptext-right {
  visibility: visible;
}
.tooltip-container-not-dots:hover .tooltiptext-bottom {
  visibility: visible;
}
.tooltip-container-not-dots:hover .tooltiptext,
.tooltiptext-right {
  visibility: visible;
}
.tooltip-container:hover .tooltiptext-right {
  visibility: visible;
}
.tooltip-container-not-dots:hover .tooltiptext-right {
  visibility: visible;
}
.tooltip-container-not-dots:focus .tooltiptext-right {
  visibility: visible;
}
.tooltip-container-not-dots:focus .tooltiptext-bottom {
  visibility: visible;
}
.tooltip-container-not-dots:hover .tooltiptext-bottom {
  visibility: visible;
}
.tooltip-container-not-dots:hover .tooltiptext-left {
  visibility: visible;
}
.tooltip-container-not-dots:focus .tooltiptext-left {
  visibility: visible;
}
.tooltip-container-not-dots:hover .tooltiptext-right2 {
  visibility: visible;
}
.tooltip-container-not-dots:focus .tooltiptext-right2 {
  visibility: visible;
}
.mat-slide-toggle-bar {
  background-color: rgba(184, 185, 185, 0.54) !important;
}
.mat-slide-toggle .mat-slide-toggle-thumb {
  background-color: #0068dc !important;
}
.mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
  background-color: #0068dc !important;
}
.upload-icon {
  content: url('upload_icon.b1f87917a87b8b0c1250.svg');
  width: 21px;
  height: 21px;
}
.post-add {
  content: url('post_add.c0a223be7c5c0ef5a7d2.svg');
  width: 21px;
  height: 21px;
  filter: invert(81%) sepia(0%) saturate(0%) hue-rotate(191deg) brightness(87%) contrast(87%);
}
.loader-icon {
  content: url('loading-spinner.63ca612450589dc5043c.svg');
  width: 18px;
  height: 18px;
  filter: invert(34%) sepia(90%) saturate(6351%) hue-rotate(202deg) brightness(97%) contrast(101%);
}
.info-icon {
  content: url('info-24px.20959f6f4f80a991d664.svg');
  width: 18px;
  height: 18px;
}
.more-dots {
  content: url('more_dots.4da4ec13d460d3e30461.svg');
  width: 18px;
  height: 18px;
}
.download-off {
  content: url('download-off.154550a2b9bb418a75c1.svg');
  width: 21px;
  height: 21px;
}
.hyperlink {
  cursor: pointer;
  color: #0068DC;
  font-weight: 500;
}
fs-ui-button {
  display: block;
  width: 100%;
  margin-bottom: 20px;
}
@media screen and (min-width: 961px) {
  fs-ui-button {
    width: 300px;
  }
}
.actionButtons fs-ui-button:first-child {
  margin-right: 10px;
}
.button-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.button-direction-column {
  flex-direction: column;
}
.eval-red {
  color: #FF0000;
  font-weight: 400;
}
.eval-green {
  color: #12AB64;
  font-weight: 400;
}
.arrow-upward {
  content: url('arrow_upward_black_24dp.38ebbd40e65c95e1ad63.svg');
  width: 18px;
  height: 18px;
  filter: invert(47%) sepia(8%) saturate(533%) hue-rotate(179deg) brightness(90%) contrast(83%);
}
.arrow-downward {
  content: url('arrow_downward_black_24dp.7addfbe23d2592e3bfc1.svg');
  width: 18px;
  height: 18px;
  filter: invert(47%) sepia(8%) saturate(533%) hue-rotate(179deg) brightness(90%) contrast(83%);
}
.expand-less {
  content: url('expand_less.793e0ed4d9129307b37b.svg');
  width: 18px;
  height: 18px;
  filter: invert(47%) sepia(8%) saturate(533%) hue-rotate(179deg) brightness(90%) contrast(83%);
}
.expand-more {
  content: url('expand_more.b9515b77c056853076b1.svg');
  width: 18px;
  height: 18px;
  filter: invert(47%) sepia(8%) saturate(533%) hue-rotate(179deg) brightness(90%) contrast(83%);
}
.arrow-forward {
  content: url('arrow_forward-24px.45bbe5f59aaf028e7d4d.svg');
  width: 18px;
  height: 18px;
  filter: invert(47%) sepia(8%) saturate(533%) hue-rotate(179deg) brightness(90%) contrast(83%);
}
lib-invoice-card {
  display: block;
  margin-bottom: 35px;
}
@media screen and (min-width: 961px) {
  lib-invoice-card {
    margin-bottom: 50px;
  }
}
lib-text-icon .dotted {
  max-width: calc(100vw - 860px) !important;
}
:host ::ng-deep mat-dialog-container lib-finsmart-banks-layout .actions-buttons-container fs-ui-button {
  width: 100% !important;
}
html.cdk-global-scrollblock {
  position: initial !important;
}
.iti {
  position: relative;
  display: inline-block; }
  .iti * {
    box-sizing: border-box;
    -moz-box-sizing: border-box; }
  .iti__hide {
    display: none; }
  .iti__v-hide {
    visibility: hidden; }
  .iti input, .iti input[type=text], .iti input[type=tel] {
    position: relative;
    z-index: 0;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    padding-right: 36px;
    margin-right: 0; }
  .iti__flag-container {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    padding: 1px; }
  .iti__selected-flag {
    z-index: 1;
    position: relative;
    display: flex;
    align-items: center;
    height: 100%;
    padding: 0 6px 0 8px; }
  .iti__arrow {
    margin-left: 6px;
    width: 0;
    height: 0;
    border-left: 3px solid transparent;
    border-right: 3px solid transparent;
    border-top: 4px solid #555; }
  .iti__arrow--up {
      border-top: none;
      border-bottom: 4px solid #555; }
  .iti__country-list {
    position: absolute;
    z-index: 2;
    list-style: none;
    text-align: left;
    padding: 0;
    margin: 0 0 0 -1px;
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2);
    background-color: white;
    border: 1px solid #CCC;
    white-space: nowrap;
    max-height: 200px;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch; }
  .iti__country-list--dropup {
      bottom: 100%;
      margin-bottom: -1px; }
  @media (max-width: 500px) {
      .iti__country-list {
        white-space: normal; } }
  .iti__flag-box {
    display: inline-block;
    width: 20px; }
  .iti__divider {
    padding-bottom: 5px;
    margin-bottom: 5px;
    border-bottom: 1px solid #CCC; }
  .iti__country {
    padding: 5px 10px;
    outline: none; }
  .iti__dial-code {
    color: #999; }
  .iti__country.iti__highlight {
    background-color: rgba(0, 0, 0, 0.05); }
  .iti__flag-box, .iti__country-name, .iti__dial-code {
    vertical-align: middle; }
  .iti__flag-box, .iti__country-name {
    margin-right: 6px; }
  .iti--allow-dropdown input, .iti--allow-dropdown input[type=text], .iti--allow-dropdown input[type=tel], .iti--separate-dial-code input, .iti--separate-dial-code input[type=text], .iti--separate-dial-code input[type=tel] {
    padding-right: 6px;
    padding-left: 52px;
    margin-left: 0; }
  .iti--allow-dropdown .iti__flag-container, .iti--separate-dial-code .iti__flag-container {
    right: auto;
    left: 0; }
  .iti--allow-dropdown .iti__flag-container:hover {
    cursor: pointer; }
  .iti--allow-dropdown .iti__flag-container:hover .iti__selected-flag {
      background-color: rgba(0, 0, 0, 0.05); }
  .iti--allow-dropdown input[disabled] + .iti__flag-container:hover,
  .iti--allow-dropdown input[readonly] + .iti__flag-container:hover {
    cursor: default; }
  .iti--allow-dropdown input[disabled] + .iti__flag-container:hover .iti__selected-flag,
    .iti--allow-dropdown input[readonly] + .iti__flag-container:hover .iti__selected-flag {
      background-color: transparent; }
  .iti--separate-dial-code .iti__selected-flag {
    background-color: rgba(0, 0, 0, 0.05); }
  .iti--separate-dial-code .iti__selected-dial-code {
    margin-left: 6px; }
  .iti--container {
    position: absolute;
    top: -1000px;
    left: -1000px;
    z-index: 1060;
    padding: 1px; }
  .iti--container:hover {
      cursor: pointer; }
  .iti-mobile .iti--container {
  top: 30px;
  bottom: 30px;
  left: 30px;
  right: 30px;
  position: fixed; }
  .iti-mobile .iti__country-list {
  max-height: 100%;
  width: 100%; }
  .iti-mobile .iti__country {
  padding: 10px 10px;
  line-height: 1.5em; }
  .iti__flag {
  width: 20px; }
  .iti__flag.iti__be {
    width: 18px; }
  .iti__flag.iti__ch {
    width: 15px; }
  .iti__flag.iti__mc {
    width: 19px; }
  .iti__flag.iti__ne {
    width: 18px; }
  .iti__flag.iti__np {
    width: 13px; }
  .iti__flag.iti__va {
    width: 15px; }
  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    .iti__flag {
      background-size: 5652px 15px; } }
  .iti__flag.iti__ac {
    height: 10px;
    background-position: 0px 0px; }
  .iti__flag.iti__ad {
    height: 14px;
    background-position: -22px 0px; }
  .iti__flag.iti__ae {
    height: 10px;
    background-position: -44px 0px; }
  .iti__flag.iti__af {
    height: 14px;
    background-position: -66px 0px; }
  .iti__flag.iti__ag {
    height: 14px;
    background-position: -88px 0px; }
  .iti__flag.iti__ai {
    height: 10px;
    background-position: -110px 0px; }
  .iti__flag.iti__al {
    height: 15px;
    background-position: -132px 0px; }
  .iti__flag.iti__am {
    height: 10px;
    background-position: -154px 0px; }
  .iti__flag.iti__ao {
    height: 14px;
    background-position: -176px 0px; }
  .iti__flag.iti__aq {
    height: 14px;
    background-position: -198px 0px; }
  .iti__flag.iti__ar {
    height: 13px;
    background-position: -220px 0px; }
  .iti__flag.iti__as {
    height: 10px;
    background-position: -242px 0px; }
  .iti__flag.iti__at {
    height: 14px;
    background-position: -264px 0px; }
  .iti__flag.iti__au {
    height: 10px;
    background-position: -286px 0px; }
  .iti__flag.iti__aw {
    height: 14px;
    background-position: -308px 0px; }
  .iti__flag.iti__ax {
    height: 13px;
    background-position: -330px 0px; }
  .iti__flag.iti__az {
    height: 10px;
    background-position: -352px 0px; }
  .iti__flag.iti__ba {
    height: 10px;
    background-position: -374px 0px; }
  .iti__flag.iti__bb {
    height: 14px;
    background-position: -396px 0px; }
  .iti__flag.iti__bd {
    height: 12px;
    background-position: -418px 0px; }
  .iti__flag.iti__be {
    height: 15px;
    background-position: -440px 0px; }
  .iti__flag.iti__bf {
    height: 14px;
    background-position: -460px 0px; }
  .iti__flag.iti__bg {
    height: 12px;
    background-position: -482px 0px; }
  .iti__flag.iti__bh {
    height: 12px;
    background-position: -504px 0px; }
  .iti__flag.iti__bi {
    height: 12px;
    background-position: -526px 0px; }
  .iti__flag.iti__bj {
    height: 14px;
    background-position: -548px 0px; }
  .iti__flag.iti__bl {
    height: 14px;
    background-position: -570px 0px; }
  .iti__flag.iti__bm {
    height: 10px;
    background-position: -592px 0px; }
  .iti__flag.iti__bn {
    height: 10px;
    background-position: -614px 0px; }
  .iti__flag.iti__bo {
    height: 14px;
    background-position: -636px 0px; }
  .iti__flag.iti__bq {
    height: 14px;
    background-position: -658px 0px; }
  .iti__flag.iti__br {
    height: 14px;
    background-position: -680px 0px; }
  .iti__flag.iti__bs {
    height: 10px;
    background-position: -702px 0px; }
  .iti__flag.iti__bt {
    height: 14px;
    background-position: -724px 0px; }
  .iti__flag.iti__bv {
    height: 15px;
    background-position: -746px 0px; }
  .iti__flag.iti__bw {
    height: 14px;
    background-position: -768px 0px; }
  .iti__flag.iti__by {
    height: 10px;
    background-position: -790px 0px; }
  .iti__flag.iti__bz {
    height: 14px;
    background-position: -812px 0px; }
  .iti__flag.iti__ca {
    height: 10px;
    background-position: -834px 0px; }
  .iti__flag.iti__cc {
    height: 10px;
    background-position: -856px 0px; }
  .iti__flag.iti__cd {
    height: 15px;
    background-position: -878px 0px; }
  .iti__flag.iti__cf {
    height: 14px;
    background-position: -900px 0px; }
  .iti__flag.iti__cg {
    height: 14px;
    background-position: -922px 0px; }
  .iti__flag.iti__ch {
    height: 15px;
    background-position: -944px 0px; }
  .iti__flag.iti__ci {
    height: 14px;
    background-position: -961px 0px; }
  .iti__flag.iti__ck {
    height: 10px;
    background-position: -983px 0px; }
  .iti__flag.iti__cl {
    height: 14px;
    background-position: -1005px 0px; }
  .iti__flag.iti__cm {
    height: 14px;
    background-position: -1027px 0px; }
  .iti__flag.iti__cn {
    height: 14px;
    background-position: -1049px 0px; }
  .iti__flag.iti__co {
    height: 14px;
    background-position: -1071px 0px; }
  .iti__flag.iti__cp {
    height: 14px;
    background-position: -1093px 0px; }
  .iti__flag.iti__cr {
    height: 12px;
    background-position: -1115px 0px; }
  .iti__flag.iti__cu {
    height: 10px;
    background-position: -1137px 0px; }
  .iti__flag.iti__cv {
    height: 12px;
    background-position: -1159px 0px; }
  .iti__flag.iti__cw {
    height: 14px;
    background-position: -1181px 0px; }
  .iti__flag.iti__cx {
    height: 10px;
    background-position: -1203px 0px; }
  .iti__flag.iti__cy {
    height: 14px;
    background-position: -1225px 0px; }
  .iti__flag.iti__cz {
    height: 14px;
    background-position: -1247px 0px; }
  .iti__flag.iti__de {
    height: 12px;
    background-position: -1269px 0px; }
  .iti__flag.iti__dg {
    height: 10px;
    background-position: -1291px 0px; }
  .iti__flag.iti__dj {
    height: 14px;
    background-position: -1313px 0px; }
  .iti__flag.iti__dk {
    height: 15px;
    background-position: -1335px 0px; }
  .iti__flag.iti__dm {
    height: 10px;
    background-position: -1357px 0px; }
  .iti__flag.iti__do {
    height: 14px;
    background-position: -1379px 0px; }
  .iti__flag.iti__dz {
    height: 14px;
    background-position: -1401px 0px; }
  .iti__flag.iti__ea {
    height: 14px;
    background-position: -1423px 0px; }
  .iti__flag.iti__ec {
    height: 14px;
    background-position: -1445px 0px; }
  .iti__flag.iti__ee {
    height: 13px;
    background-position: -1467px 0px; }
  .iti__flag.iti__eg {
    height: 14px;
    background-position: -1489px 0px; }
  .iti__flag.iti__eh {
    height: 10px;
    background-position: -1511px 0px; }
  .iti__flag.iti__er {
    height: 10px;
    background-position: -1533px 0px; }
  .iti__flag.iti__es {
    height: 14px;
    background-position: -1555px 0px; }
  .iti__flag.iti__et {
    height: 10px;
    background-position: -1577px 0px; }
  .iti__flag.iti__eu {
    height: 14px;
    background-position: -1599px 0px; }
  .iti__flag.iti__fi {
    height: 12px;
    background-position: -1621px 0px; }
  .iti__flag.iti__fj {
    height: 10px;
    background-position: -1643px 0px; }
  .iti__flag.iti__fk {
    height: 10px;
    background-position: -1665px 0px; }
  .iti__flag.iti__fm {
    height: 11px;
    background-position: -1687px 0px; }
  .iti__flag.iti__fo {
    height: 15px;
    background-position: -1709px 0px; }
  .iti__flag.iti__fr {
    height: 14px;
    background-position: -1731px 0px; }
  .iti__flag.iti__ga {
    height: 15px;
    background-position: -1753px 0px; }
  .iti__flag.iti__gb {
    height: 10px;
    background-position: -1775px 0px; }
  .iti__flag.iti__gd {
    height: 12px;
    background-position: -1797px 0px; }
  .iti__flag.iti__ge {
    height: 14px;
    background-position: -1819px 0px; }
  .iti__flag.iti__gf {
    height: 14px;
    background-position: -1841px 0px; }
  .iti__flag.iti__gg {
    height: 14px;
    background-position: -1863px 0px; }
  .iti__flag.iti__gh {
    height: 14px;
    background-position: -1885px 0px; }
  .iti__flag.iti__gi {
    height: 10px;
    background-position: -1907px 0px; }
  .iti__flag.iti__gl {
    height: 14px;
    background-position: -1929px 0px; }
  .iti__flag.iti__gm {
    height: 14px;
    background-position: -1951px 0px; }
  .iti__flag.iti__gn {
    height: 14px;
    background-position: -1973px 0px; }
  .iti__flag.iti__gp {
    height: 14px;
    background-position: -1995px 0px; }
  .iti__flag.iti__gq {
    height: 14px;
    background-position: -2017px 0px; }
  .iti__flag.iti__gr {
    height: 14px;
    background-position: -2039px 0px; }
  .iti__flag.iti__gs {
    height: 10px;
    background-position: -2061px 0px; }
  .iti__flag.iti__gt {
    height: 13px;
    background-position: -2083px 0px; }
  .iti__flag.iti__gu {
    height: 11px;
    background-position: -2105px 0px; }
  .iti__flag.iti__gw {
    height: 10px;
    background-position: -2127px 0px; }
  .iti__flag.iti__gy {
    height: 12px;
    background-position: -2149px 0px; }
  .iti__flag.iti__hk {
    height: 14px;
    background-position: -2171px 0px; }
  .iti__flag.iti__hm {
    height: 10px;
    background-position: -2193px 0px; }
  .iti__flag.iti__hn {
    height: 10px;
    background-position: -2215px 0px; }
  .iti__flag.iti__hr {
    height: 10px;
    background-position: -2237px 0px; }
  .iti__flag.iti__ht {
    height: 12px;
    background-position: -2259px 0px; }
  .iti__flag.iti__hu {
    height: 10px;
    background-position: -2281px 0px; }
  .iti__flag.iti__ic {
    height: 14px;
    background-position: -2303px 0px; }
  .iti__flag.iti__id {
    height: 14px;
    background-position: -2325px 0px; }
  .iti__flag.iti__ie {
    height: 10px;
    background-position: -2347px 0px; }
  .iti__flag.iti__il {
    height: 15px;
    background-position: -2369px 0px; }
  .iti__flag.iti__im {
    height: 10px;
    background-position: -2391px 0px; }
  .iti__flag.iti__in {
    height: 14px;
    background-position: -2413px 0px; }
  .iti__flag.iti__io {
    height: 10px;
    background-position: -2435px 0px; }
  .iti__flag.iti__iq {
    height: 14px;
    background-position: -2457px 0px; }
  .iti__flag.iti__ir {
    height: 12px;
    background-position: -2479px 0px; }
  .iti__flag.iti__is {
    height: 15px;
    background-position: -2501px 0px; }
  .iti__flag.iti__it {
    height: 14px;
    background-position: -2523px 0px; }
  .iti__flag.iti__je {
    height: 12px;
    background-position: -2545px 0px; }
  .iti__flag.iti__jm {
    height: 10px;
    background-position: -2567px 0px; }
  .iti__flag.iti__jo {
    height: 10px;
    background-position: -2589px 0px; }
  .iti__flag.iti__jp {
    height: 14px;
    background-position: -2611px 0px; }
  .iti__flag.iti__ke {
    height: 14px;
    background-position: -2633px 0px; }
  .iti__flag.iti__kg {
    height: 12px;
    background-position: -2655px 0px; }
  .iti__flag.iti__kh {
    height: 13px;
    background-position: -2677px 0px; }
  .iti__flag.iti__ki {
    height: 10px;
    background-position: -2699px 0px; }
  .iti__flag.iti__km {
    height: 12px;
    background-position: -2721px 0px; }
  .iti__flag.iti__kn {
    height: 14px;
    background-position: -2743px 0px; }
  .iti__flag.iti__kp {
    height: 10px;
    background-position: -2765px 0px; }
  .iti__flag.iti__kr {
    height: 14px;
    background-position: -2787px 0px; }
  .iti__flag.iti__kw {
    height: 10px;
    background-position: -2809px 0px; }
  .iti__flag.iti__ky {
    height: 10px;
    background-position: -2831px 0px; }
  .iti__flag.iti__kz {
    height: 10px;
    background-position: -2853px 0px; }
  .iti__flag.iti__la {
    height: 14px;
    background-position: -2875px 0px; }
  .iti__flag.iti__lb {
    height: 14px;
    background-position: -2897px 0px; }
  .iti__flag.iti__lc {
    height: 10px;
    background-position: -2919px 0px; }
  .iti__flag.iti__li {
    height: 12px;
    background-position: -2941px 0px; }
  .iti__flag.iti__lk {
    height: 10px;
    background-position: -2963px 0px; }
  .iti__flag.iti__lr {
    height: 11px;
    background-position: -2985px 0px; }
  .iti__flag.iti__ls {
    height: 14px;
    background-position: -3007px 0px; }
  .iti__flag.iti__lt {
    height: 12px;
    background-position: -3029px 0px; }
  .iti__flag.iti__lu {
    height: 12px;
    background-position: -3051px 0px; }
  .iti__flag.iti__lv {
    height: 10px;
    background-position: -3073px 0px; }
  .iti__flag.iti__ly {
    height: 10px;
    background-position: -3095px 0px; }
  .iti__flag.iti__ma {
    height: 14px;
    background-position: -3117px 0px; }
  .iti__flag.iti__mc {
    height: 15px;
    background-position: -3139px 0px; }
  .iti__flag.iti__md {
    height: 10px;
    background-position: -3160px 0px; }
  .iti__flag.iti__me {
    height: 10px;
    background-position: -3182px 0px; }
  .iti__flag.iti__mf {
    height: 14px;
    background-position: -3204px 0px; }
  .iti__flag.iti__mg {
    height: 14px;
    background-position: -3226px 0px; }
  .iti__flag.iti__mh {
    height: 11px;
    background-position: -3248px 0px; }
  .iti__flag.iti__mk {
    height: 10px;
    background-position: -3270px 0px; }
  .iti__flag.iti__ml {
    height: 14px;
    background-position: -3292px 0px; }
  .iti__flag.iti__mm {
    height: 14px;
    background-position: -3314px 0px; }
  .iti__flag.iti__mn {
    height: 10px;
    background-position: -3336px 0px; }
  .iti__flag.iti__mo {
    height: 14px;
    background-position: -3358px 0px; }
  .iti__flag.iti__mp {
    height: 10px;
    background-position: -3380px 0px; }
  .iti__flag.iti__mq {
    height: 14px;
    background-position: -3402px 0px; }
  .iti__flag.iti__mr {
    height: 14px;
    background-position: -3424px 0px; }
  .iti__flag.iti__ms {
    height: 10px;
    background-position: -3446px 0px; }
  .iti__flag.iti__mt {
    height: 14px;
    background-position: -3468px 0px; }
  .iti__flag.iti__mu {
    height: 14px;
    background-position: -3490px 0px; }
  .iti__flag.iti__mv {
    height: 14px;
    background-position: -3512px 0px; }
  .iti__flag.iti__mw {
    height: 14px;
    background-position: -3534px 0px; }
  .iti__flag.iti__mx {
    height: 12px;
    background-position: -3556px 0px; }
  .iti__flag.iti__my {
    height: 10px;
    background-position: -3578px 0px; }
  .iti__flag.iti__mz {
    height: 14px;
    background-position: -3600px 0px; }
  .iti__flag.iti__na {
    height: 14px;
    background-position: -3622px 0px; }
  .iti__flag.iti__nc {
    height: 10px;
    background-position: -3644px 0px; }
  .iti__flag.iti__ne {
    height: 15px;
    background-position: -3666px 0px; }
  .iti__flag.iti__nf {
    height: 10px;
    background-position: -3686px 0px; }
  .iti__flag.iti__ng {
    height: 10px;
    background-position: -3708px 0px; }
  .iti__flag.iti__ni {
    height: 12px;
    background-position: -3730px 0px; }
  .iti__flag.iti__nl {
    height: 14px;
    background-position: -3752px 0px; }
  .iti__flag.iti__no {
    height: 15px;
    background-position: -3774px 0px; }
  .iti__flag.iti__np {
    height: 15px;
    background-position: -3796px 0px; }
  .iti__flag.iti__nr {
    height: 10px;
    background-position: -3811px 0px; }
  .iti__flag.iti__nu {
    height: 10px;
    background-position: -3833px 0px; }
  .iti__flag.iti__nz {
    height: 10px;
    background-position: -3855px 0px; }
  .iti__flag.iti__om {
    height: 10px;
    background-position: -3877px 0px; }
  .iti__flag.iti__pa {
    height: 14px;
    background-position: -3899px 0px; }
  .iti__flag.iti__pe {
    height: 14px;
    background-position: -3921px 0px; }
  .iti__flag.iti__pf {
    height: 14px;
    background-position: -3943px 0px; }
  .iti__flag.iti__pg {
    height: 15px;
    background-position: -3965px 0px; }
  .iti__flag.iti__ph {
    height: 10px;
    background-position: -3987px 0px; }
  .iti__flag.iti__pk {
    height: 14px;
    background-position: -4009px 0px; }
  .iti__flag.iti__pl {
    height: 13px;
    background-position: -4031px 0px; }
  .iti__flag.iti__pm {
    height: 14px;
    background-position: -4053px 0px; }
  .iti__flag.iti__pn {
    height: 10px;
    background-position: -4075px 0px; }
  .iti__flag.iti__pr {
    height: 14px;
    background-position: -4097px 0px; }
  .iti__flag.iti__ps {
    height: 10px;
    background-position: -4119px 0px; }
  .iti__flag.iti__pt {
    height: 14px;
    background-position: -4141px 0px; }
  .iti__flag.iti__pw {
    height: 13px;
    background-position: -4163px 0px; }
  .iti__flag.iti__py {
    height: 11px;
    background-position: -4185px 0px; }
  .iti__flag.iti__qa {
    height: 8px;
    background-position: -4207px 0px; }
  .iti__flag.iti__re {
    height: 14px;
    background-position: -4229px 0px; }
  .iti__flag.iti__ro {
    height: 14px;
    background-position: -4251px 0px; }
  .iti__flag.iti__rs {
    height: 14px;
    background-position: -4273px 0px; }
  .iti__flag.iti__ru {
    height: 14px;
    background-position: -4295px 0px; }
  .iti__flag.iti__rw {
    height: 14px;
    background-position: -4317px 0px; }
  .iti__flag.iti__sa {
    height: 14px;
    background-position: -4339px 0px; }
  .iti__flag.iti__sb {
    height: 10px;
    background-position: -4361px 0px; }
  .iti__flag.iti__sc {
    height: 10px;
    background-position: -4383px 0px; }
  .iti__flag.iti__sd {
    height: 10px;
    background-position: -4405px 0px; }
  .iti__flag.iti__se {
    height: 13px;
    background-position: -4427px 0px; }
  .iti__flag.iti__sg {
    height: 14px;
    background-position: -4449px 0px; }
  .iti__flag.iti__sh {
    height: 10px;
    background-position: -4471px 0px; }
  .iti__flag.iti__si {
    height: 10px;
    background-position: -4493px 0px; }
  .iti__flag.iti__sj {
    height: 15px;
    background-position: -4515px 0px; }
  .iti__flag.iti__sk {
    height: 14px;
    background-position: -4537px 0px; }
  .iti__flag.iti__sl {
    height: 14px;
    background-position: -4559px 0px; }
  .iti__flag.iti__sm {
    height: 15px;
    background-position: -4581px 0px; }
  .iti__flag.iti__sn {
    height: 14px;
    background-position: -4603px 0px; }
  .iti__flag.iti__so {
    height: 14px;
    background-position: -4625px 0px; }
  .iti__flag.iti__sr {
    height: 14px;
    background-position: -4647px 0px; }
  .iti__flag.iti__ss {
    height: 10px;
    background-position: -4669px 0px; }
  .iti__flag.iti__st {
    height: 10px;
    background-position: -4691px 0px; }
  .iti__flag.iti__sv {
    height: 12px;
    background-position: -4713px 0px; }
  .iti__flag.iti__sx {
    height: 14px;
    background-position: -4735px 0px; }
  .iti__flag.iti__sy {
    height: 14px;
    background-position: -4757px 0px; }
  .iti__flag.iti__sz {
    height: 14px;
    background-position: -4779px 0px; }
  .iti__flag.iti__ta {
    height: 10px;
    background-position: -4801px 0px; }
  .iti__flag.iti__tc {
    height: 10px;
    background-position: -4823px 0px; }
  .iti__flag.iti__td {
    height: 14px;
    background-position: -4845px 0px; }
  .iti__flag.iti__tf {
    height: 14px;
    background-position: -4867px 0px; }
  .iti__flag.iti__tg {
    height: 13px;
    background-position: -4889px 0px; }
  .iti__flag.iti__th {
    height: 14px;
    background-position: -4911px 0px; }
  .iti__flag.iti__tj {
    height: 10px;
    background-position: -4933px 0px; }
  .iti__flag.iti__tk {
    height: 10px;
    background-position: -4955px 0px; }
  .iti__flag.iti__tl {
    height: 10px;
    background-position: -4977px 0px; }
  .iti__flag.iti__tm {
    height: 14px;
    background-position: -4999px 0px; }
  .iti__flag.iti__tn {
    height: 14px;
    background-position: -5021px 0px; }
  .iti__flag.iti__to {
    height: 10px;
    background-position: -5043px 0px; }
  .iti__flag.iti__tr {
    height: 14px;
    background-position: -5065px 0px; }
  .iti__flag.iti__tt {
    height: 12px;
    background-position: -5087px 0px; }
  .iti__flag.iti__tv {
    height: 10px;
    background-position: -5109px 0px; }
  .iti__flag.iti__tw {
    height: 14px;
    background-position: -5131px 0px; }
  .iti__flag.iti__tz {
    height: 14px;
    background-position: -5153px 0px; }
  .iti__flag.iti__ua {
    height: 14px;
    background-position: -5175px 0px; }
  .iti__flag.iti__ug {
    height: 14px;
    background-position: -5197px 0px; }
  .iti__flag.iti__um {
    height: 11px;
    background-position: -5219px 0px; }
  .iti__flag.iti__un {
    height: 14px;
    background-position: -5241px 0px; }
  .iti__flag.iti__us {
    height: 11px;
    background-position: -5263px 0px; }
  .iti__flag.iti__uy {
    height: 14px;
    background-position: -5285px 0px; }
  .iti__flag.iti__uz {
    height: 10px;
    background-position: -5307px 0px; }
  .iti__flag.iti__va {
    height: 15px;
    background-position: -5329px 0px; }
  .iti__flag.iti__vc {
    height: 14px;
    background-position: -5346px 0px; }
  .iti__flag.iti__ve {
    height: 14px;
    background-position: -5368px 0px; }
  .iti__flag.iti__vg {
    height: 10px;
    background-position: -5390px 0px; }
  .iti__flag.iti__vi {
    height: 14px;
    background-position: -5412px 0px; }
  .iti__flag.iti__vn {
    height: 14px;
    background-position: -5434px 0px; }
  .iti__flag.iti__vu {
    height: 12px;
    background-position: -5456px 0px; }
  .iti__flag.iti__wf {
    height: 14px;
    background-position: -5478px 0px; }
  .iti__flag.iti__ws {
    height: 10px;
    background-position: -5500px 0px; }
  .iti__flag.iti__xk {
    height: 15px;
    background-position: -5522px 0px; }
  .iti__flag.iti__ye {
    height: 14px;
    background-position: -5544px 0px; }
  .iti__flag.iti__yt {
    height: 14px;
    background-position: -5566px 0px; }
  .iti__flag.iti__za {
    height: 14px;
    background-position: -5588px 0px; }
  .iti__flag.iti__zm {
    height: 14px;
    background-position: -5610px 0px; }
  .iti__flag.iti__zw {
    height: 10px;
    background-position: -5632px 0px; }
  .iti__flag {
  height: 15px;
  box-shadow: 0px 0px 1px 0px #888;
  background-image: url('flags.007b2705c0a8f69dfdf6.png');
  background-repeat: no-repeat;
  background-color: #DBDBDB;
  background-position: 20px 0; }
  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    .iti__flag {
      background-image: url('flags@2x.9d5328fb490cddd43f66.png'); } }
  .iti__flag.iti__np {
  background-color: transparent; }

