@import 'palette';

// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import '~@angular/material/theming';
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$finsmart-web-primary: mat-palette($mat-indigo);
$finsmart-web-accent: mat-palette($mat-pink, A200, A100, A400);

// The warn palette is optional (defaults to red).
$finsmart-web-warn: mat-palette($mat-red);

// Create the theme object (a Sass map containing all of the palettes).
$finsmart-web-theme: mat-light-theme(
  $finsmart-web-primary,
  $finsmart-web-accent,
  $finsmart-web-warn
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($finsmart-web-theme);

.custom-mat-invalid-style mat-label,
.custom-mat-invalid-style p {
  color: red;
}
.custom-mat-invalid-style .mat-form-field-underline {
  background-color: red;
}
.custom-mat-invalid-style .mat-form-field-underline {
  background-color: red;
}

.custom-mat-invalid-style .mat-radio-outer-circle {
  border-color: red;
}
.custom-mat-invalid-style .mat-radio-button {
  color: red;
}

.mat-radio-button.mat-accent .mat-radio-inner-circle {
  background-color: $blue !important;
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: $blue !important;
}
// .mat-radio-outer-circle {
//   width: 16px !important;
//   height: 16px !important;
//   border-width: 1px !important;
// }
// .mat-radio-checked .mat-radio-inner-circle {
//     transform: scale(0.3) !important;
// }
// .mat-radio-inner-circle {
//     width: 16px !important;
//     height: 16px !important;
// }
// .mat-radio-container {
//     width: 16px !important;
//     height: 16px !important;
// }
