@import 'responsive';
@import 'palette';


html {
  box-sizing: border-box;
}

/* THIS CAUSES INPUT TO NOT WORK
ALSO ACCORDRING TO THIS, IT'S NOT GOOD FROM A UX STANDING POINT
https://ux.stackexchange.com/a/83228
*,
*:before,
*:after {
  box-sizing: inherit;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
   -khtml-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
*/
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font-weight: 300;
  font-family: 'Chivo', sans-serif;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
  margin: 20px 16px !important;
  @media screen and (min-width: 321px) {
    margin-left: 24px !important;
    margin-right: 24px !important;
  }
  margin-top: 20px !important;
}
a {
  text-decoration: none;
  color: #000000;
}

ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote {
  &:before,
  &:after {
    content: '';
    content: none;
  }
}
q {
  &:before,
  &:after {
    content: '';
    content: none;
  }
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: none;
  -webkit-text-fill-color: none;
  -webkit-box-shadow: 0 0 0px 1000px #ffffff inset;
  box-shadow: 0 0 0px 1000px #ffffff inset;
  transition: background-color 5000s ease-in-out 0s;
}
button {
  border: none;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 500 !important;
}

::ng-deep {
  .toast-card.success {
    background-color: $green !important;
  }

  .toast-card.danger {
    background-color: $red !important;
  }
}
