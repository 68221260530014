@import 'responsive';

.one-line {
    display: flex;
}
.one-line.center-dp {
    @media #{$desktop-bp} {
        justify-content: center;
    }
}
.one-line > * {
    margin-right: 10%;
}
.one-line > *:last-child {
    margin-right: 0;

    @media #{$desktop-bp} {
        //margin: auto;
    }
}

.one-line > *:first-child {
    @media #{$desktop-bp} {
        margin-left: 0;
        //margin: auto;
    }
}