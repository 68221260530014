@import url('https://fonts.googleapis.com/css2?family=Chivo:wght@300;400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@300;400;500;700&display=swap');

@font-face {
    font-family: 'icomoon';
    src:  url('../assets/icomoon/icomoon.eot?rz5ezy');
    src:  url('../assets/icomoon/icomoon.eot') format('embedded-opentype'),
      url('../assets/icomoon/icomoon.ttf') format('truetype'),
      url('../assets/icomoon/icomoon.woff') format('woff'),
      url('../assets/icomoon/icomoon.svg') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}