$white: #FFFFFF;
$black: #2D2D2D;
$red: #FF0000;
$green: #12AB64;
$yellow: #FFC400;

$dark-blue: #3E0CEF;
$blue: #0068DC;
$blue-light: #0256FF;

$turquoise: #33FDCF;
$aqua: #00FDFF;

$dark-gray: #6c727c;
$gray: #979797;
$light-gray: #CACACA;
$light-gray2: #F2F2F2;