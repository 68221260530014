@import 'palette';

.audio-progress-bar {
  &.mat-progress-bar {
    border-radius: 5px;
    height: 6px;
    margin-bottom: 5px;
  }

  .mat-progress-bar-fill::after {
    background-color: $blue;
  }

  .mat-progress-bar-buffer {
    background-color: #bdc4c7;
  }

  /* remove animation and the dots*/ 
  .mat-progress-bar-background {
    animation: none;
    background-color: #eceff1;
    fill: #eceff1;
  }
}